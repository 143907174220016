@font-face {
    font-family: Popins-Semibold;
    src: url('../assets/WONO_images/img/fonts/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: ComicSansMS;
    src: url('../assets/WONO_images/img/fonts/ComicSansMS.ttf');
}

@font-face {
    font-family: Popins-Regular;
    src: url('../assets/WONO_images/img/fonts/Poppins-Regular.ttf');
}

.privacy-section {
    /* padding-bottom: 2rem; */
}

.privSect2 {
    /* padding-bottom: 1rem; */
    padding-bottom: 0;
    font-family: Popins-Regular !important;
}

.privSect2 h4 {
    margin-bottom: 0;
    font-family: Popins-Regular !important;
    font-size: 1.3rem;
    font-weight: bold;
}

.privSect2 p {
    font-size: 1rem;
    margin-bottom: 0.8rem;
    font-family: Popins-Regular !important;
}

.privSect2 ul {

    margin-bottom: 1.8rem;

}

.WONOBLUE {
    color: #0AA9EF;
}

.ComicFont {
    font-family: ComicSansMS;
}

.privacy-container {
    font-family: Popins-Semibold;
    padding: 3rem;
}

.privacy-section>ul>li b {
    font-family: Popins-Semibold;
}

.privacy-section h4 {
    font-family: Popins-Semibold;
}

.privacy-container div {
    margin: 1rem 0 1rem 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #364D59;
}

.faq-title {
    font-family: ComicSansMS;
    position: relative;
    width: 100%;
    text-transform: uppercase;
}

.faq-title img {
    position: absolute;
    /* top: 105%; */
    top: 58%;
    left: 11.5%;
    width: 76%;
    height: 23%;

    /* position: absolute;
        top: 54%;
        left: 36%;
        width: 26%;
        height: 23%; */
}

@media (min-width: 1025px) {

    .paddingForTab {
        padding-left: 7rem !important;
        padding-right: 7rem !important;
    }


}

@media (min-width: 820px) and (max-width: 1024px) {
    .paddingForTab {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }
}

@media (min-width: 768px) and (max-width: 820px) {

    .paddingForTab {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    /* .wono-text-spacing {
        padding-left: 3rem;
        padding-right: 3rem;
    } */

    /* .privacy-container {
        padding: 1.2rem;
    } */
}

@media (max-width:768px) {
    .privacy-container {
        padding: 0.8rem;
    }
}


/* Display PC layout on larger screens (e.g., desktop) */
@media (min-width: 768px) {
    /* .pc-screen {
        display: flex;
      
    } */

    .wono-text-spacing {
        padding-left: 7rem;
        padding-right: 7rem;
    }
}

/* Display mobile layout on smaller screens */
@media (max-width: 767px) {
    /* .mobile-screen {
        display: block;
     
    } */

    .wono-text-spacing {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .faq-title img {
        position: absolute;
        /* top: 105%; */
        top: 64%;
        left: 38.5%;
        width: 20%;
        height: 23%;

        /* position: absolute;
            top: 54%;
            left: 36%;
            width: 26%;
            height: 23%; */
    }

    .paddingForTab {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
}
@media (max-width: 450px) {
    /* .mobile-screen {
        display: block;
     
    } */

    .wono-text-spacing {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .faq-title {
        font-family: ComicSansMS;
        position: relative;
        width: 100%;
        text-transform: uppercase;
        font-size: 1.1rem;
        white-space: nowrap;
    }

    .faq-title img {
        position: absolute;
        top: 68%;
        left: 4.5%;
        width: 92%;
        height: 17%;
    }

    .paddingForTab {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
}
@media (max-width: 321px) {
    /* .mobile-screen {
        display: block;
     
    } */

    .wono-text-spacing {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .faq-title {
        font-family: ComicSansMS;
        position: relative;
        width: 100%;
        text-transform: uppercase;
        font-size: 0.81rem;
        white-space: nowrap;
    }

    .faq-title img {
        position: absolute;
        top: 61%;
        left: 1.5%;
        width: 100%;
        height: 13%;
    }

    .paddingForTab {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
}