@font-face {
    font-family: Popins-SemiBold;
    src: url('../assets/WONO_images/img/fonts/Poppins-SemiBold.ttf');
}

.border {
    width: 5%;
    border-bottom: 3px solid orange;
}

.jobrole-arrow {
    margin: 0 0 0 1rem;
    font-weight: 600;
    color: #52565e;
    border: 2px solid #52565e;
    padding: 8px;
    border-radius: 7px;
    transition: background-color ease 0.2s;
}

.jobrole-arrow:hover {

    color: white;
    border: 2px solid white;
    padding: 8px;

    background-color: black;
}

.link-btn {
    font-size: 0.9rem;
}

.career-jobtitle {

    margin: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
}

.accordion-button:not(.collapsed) {
    color: black !important;
}


@media (max-width: 768px) {
    .jobrole-arrow {
        margin: 0 0 0 2rem;
        font-weight: 600;
        color: #52565e;
        border: 2px solid #52565e;
        padding: 8px;
        border-radius: 7px;
        transition: background-color ease 0.2s;
    }

    .jobrole-arrow:hover {

        color: white;
        border: 2px solid white;

        background-color: black;
    }


}

@media (min-width: 768px) {
    .jobrole-arrow {
        margin: 0 0 0 3rem;
        font-weight: 600;
        color: #52565e;
        border: 2px solid #52565e;
        padding: 8px;
        border-radius: 7px;
        transition: background-color ease 0.2s;
    }


}

/* @media (max-width: 425px)
{
    .jobrole-arrow{
        margin: 0 0 0 10rem;
        font-weight: 600;
        color: #52565e;
        border: 2px solid #52565e;
        padding: 8px;
        border-radius: 7px;
        transition: background-color ease 0.2s;
    }

} */


.career-job-title {
    font-size: 1.5rem;
    margin: 10 0;
    color: #000;
    margin: 0;
}

.accordion-button:not(.collapsed) {
    /* color: var(--bs-accordion-active-color); */
    color: #0AA9EF;
    background-color: white;
    box-shadow: none;
}

.accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none;
}

@media (max-width:768px) and (min-width:414px) {
    .text-button>p {
        display: flex;
        justify-content: space-between !important;
    }

    /* .text-button{
        text-align: left !important;
    } */


}

@media only screen and (max-width:920px) and (min-width:768px) {
    .text-button>p {
        display: flex;
        justify-content: flex-end !important;
    }

    /* .text-button{
        text-align: left !important;
    }
    .link-btn{
        text-align: left !important;
    } */

}

@media (max-width:1024px) and (min-width:920px) {
    .text-button {
        text-align: right !important;
    }

    .text-button>p {
        display: flex;
        justify-content: flex-end !important;
    }

    .link-btn {
        text-align: left !important;
    }

}

@media (max-width:414px) {
    .text-button>p {
        display: flex;
        justify-content: space-between !important;
    }

    /* .text-button{
        text-align: right !important;
    } */
    .link-btn {
        text-align: left !important;
    }

}

@media only screen and (min-device-width: 414px) and (max-device-width: 425px) {
    .text-button>p {
        display: flex;
        justify-content: space-between !important;
    }

    .link-btn {
        text-align: left !important;
    }

    .jobrole-arrow {
        margin: 0 0 0 5rem;
        font-weight: 600;
        color: #52565e;
        border: 2px solid #52565e;
        padding: 8px;
        border-radius: 7px;
        transition: background-color ease 0.2s;
    }
}

@media only screen and (min-device-width: 374px) and (max-device-width: 413px) {
    .text-button>p {
        display: flex;
        justify-content: space-between !important;
    }


    .link-btn {
        text-align: left !important;
        align-items: center;
        display: flex;
    }

    .jobrole-arrow {
        margin: 0 0 0 0rem;
        font-weight: 600;
        color: #52565e;
        border: 2px solid #52565e;
        padding: 8px;
        border-radius: 7px;
        transition: background-color ease 0.2s;
    }
}




/*
i.fa.fa-cheveron-right {
    color: black;
    font-family: Popins-Semibold;
    font-size: 16px;
    margin: 0 0 0 20px;
    font-weight: "600";
    border: 2px solid #52565e;
    padding: 0.5rem;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease
}
i.fa.fa-cheveron-right:hover {
background-color: black;
color: white;
}
*/