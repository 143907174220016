/* Breadcrumbs.css */
.breadcrumb-nav {
    padding: 1rem 0.7rem;
  }
  
  .breadcrumb-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }
  
  .breadcrumb-item {
    margin-right: 12px; /* Adds space between each breadcrumb item */
  }
  
  .breadcrumb-item:last-child {
    margin-right: 10px; /* Removes space after the last breadcrumb item */
  }
  
  .breadcrumb-item a {
    text-decoration: none;
    color: #007bff;
  }
  
  .breadcrumb-item a:hover {
    text-decoration: underline;
  }
  
  .breadcrumb-item.active {
    color: #6c757d;
  }
  