@font-face {
  font-family: Popins-Regular;
  src: url('../assets/WONO_images/img/fonts/Poppins-Regular.ttf');
}

footer {
  width: 100%
}

.footer-master {
  display: flex;
  justify-content: space-between;
  padding: 0 7rem;
  column-gap: 2rem;
}

.footer-container a {
  text-decoration: none;
  font-size: 14px;
  color: white;
  font-family: Popins-Regular;
}

.footer-copyright p {
  font-family: Popins-Regular;
}

.footer-container a:hover {
  color: rgb(175, 175, 175);
}

.footer-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  font-family: Popins-Regular;
}

.footer-section-1,
.footer-section-2,
.footer-section-3 {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 0;
  text-transform: uppercase;
  font-family: Popins-Regular;
}

.footer-section-1 {
  align-items: start;
}

.footer-section-1>* {
  margin-bottom: 1rem;
}

.footer-section-2>* {
  padding: 1rem;
  font-size: 14px;
}

.footer-section-3>* {
  padding: 1rem;
  font-size: 14px;
}
@media (max-width:1024px) {
  .footer-master {
    display: flex;
    justify-content: space-between;
    padding: 0 3rem;
}
}

@media (max-width: 769px) {
  .footer-container {
    grid-template-columns: 1fr 1fr;
    column-gap: 5rem;
    row-gap: 2rem;
  }

  .footer-master {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 2rem;
    justify-content: space-between;
  }



  .footer-section-1 {
    align-items: center;
    padding: 1rem;
  }

  .footer-section-2 {
    padding-top: 0;
  }

  .footer-section-3 {
    padding-top: 0;
  }

  .footer-section-1>* {
    padding: 2rem 0 0 0;
    font-size: 14px;
    margin: 0;
  }

  .footer-section-2>* {
    padding: 0.5rem;
    font-size: 14px;
  }

  .footer-section-1,
  .footer-section-2,
  .footer-section-3 {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    padding: 0;
    text-transform: uppercase;
    font-family: Popins-Regular;
  }
  .footer-copyright p {
    font-family: Popins-Regular;
    padding: 0 1.7rem;
  }
}
@media (max-width: 450px) {
  .footer-container {
    grid-template-columns: 1fr 1fr;
    column-gap: 5rem;
    row-gap: 2rem;
  }

  .footer-master {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 2rem;
    justify-content: center;
  }



  .footer-section-1 {
    align-items: center;
    padding: 1rem;
  }

  .footer-section-2 {
    padding-top: 0;
  }

  .footer-section-3 {
    padding-top: 0;
  }

  .footer-section-1>* {
    padding: 2rem 0 0 0;
    font-size: 1rem;
    text-align: center;
    margin: 0;
  }

  .footer-section-2>* {
    padding: 0.5rem;
    font-size: 14px;
  }

  .footer-section-1,
  .footer-section-2,
  .footer-section-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    text-transform: uppercase;
    font-family: Popins-Regular;
  }
  .footer-copyright p {
    font-family: Popins-Regular;
    padding: 0 1.7rem;
  }
}

.color-of-o {
  color: rgb(13, 110, 253);
  text-decoration: none;
}

.black-background {
  background-color: black;
}