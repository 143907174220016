@font-face {
  font-family: Popins-Regular;
  src: url('../assets/WONO_images/img/fonts/Poppins-Regular.ttf');
}

.four-cards-parent * {
  font-family: Popins-Regular;
}

.four-cards-parent {
  margin: 0;
  padding: 4rem 7rem;
  background-color: #f3f3f3;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.four-cards-header {
  text-align: center;
}

.four-cards-header span {
  text-align: center;
  font-size: 4.8rem;
  font-weight: normal;
  font-family: Popins-Semibold;
}

.four-cards-wrapper {
  padding: 2rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-body {
  width: 100% !important;
}

.wonoColor {
  color: #0AA9EF;
}

/* .card_1 {
  background-color: #fff;
  border-radius: 10px;
  height: 22.125rem;
  margin: 0.5rem 19px;
  padding: 30px 20px;
  width: 270px !important;
} */

.card-icon {
  width: 45px;
  margin-bottom: 40px;
}

.card-icon img {
  width: 100%;
}

.card-title {
  display: flex;
  margin-bottom: 20px;
  font-weight: 400;
  font-family: Popins-Semibold;
  font-size: 1rem;
}

.card-subtext {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  color: #363636;
  font-size: 0.8rem;
  font-family: Popins-Regular;
}

.card-subtext img {
  width: 16px;
  margin-right: 10px;
}

.key-features-link-space {
  font-family: Popins-Semibold;
  text-align: center;
  margin-top: 20px;
}

.key-features-link {
  color: black;
  font-family: Popins-Semibold;
  /* font-weight: normal; */
}

.key-features-link:hover {
  text-decoration: underline;
  color: blue;
}

.slick-prev,
.slick-next {
  background-color: black;
  /* Background color for arrows */
  border: none;
  border-radius: 50%;
  width: 30px;
  /* Arrow size */
  height: 30px;
  /* Arrow size */
  line-height: 30px;
  text-align: center;
  font-size: 18px;
  /* Arrow symbol size */
  cursor: pointer;
  z-index: 1;
  /* Ensure arrows are above the slider */
}

.slick-prev {
  left: 70px;
  /* Distance from the left edge */
}

.slick-next {
  right: 10px;
  /* Distance from the right edge */
}

.slick-prev:before,
.slick-next:before {
  content: '';
  /* Override default content */
  display: block;
}

button.slick-prev.slick-arrow {
  left: -42px;
  margin-left: 46px;
}

button.slick-prev.slick-arrow::before {
  color: black;
  font-size: 2rem;
}

button.slick-next.slick-arrow {
  margin-right: 43px;
}

button.slick-next.slick-arrow::before {
  color: black;
  font-size: 2rem;
}


/* for 4k screen */
@media (min-width: 2500px) {
  .card_1 {
    background-color: #fff;
    border-radius: 10px;
    height: 22.125rem;
    margin: 0.5rem 91px;
    padding: 30px 20px;
    width: 288px !important;
  }
}

/* below 4k screen  */

@media (max-width: 2499px) {
  .card_1 {
    background-color: #fff;
    border-radius: 10px;
    height: 321px;
    margin: 0.5rem 23px;
    padding: 30px 20px;
    width: 266px !important;
  }
}

/* for sir's screen */


@media (max-width:1440px) {

  .card_1 {
    background-color: #fff;
    border-radius: 10px;
    /* height: 24.695rem; */
    margin: 0.5rem 20px;
    padding: 30px 20px;
    width: 248px !important;
  }

  .four-cards-header span {
    text-align: center;
    font-size: 4.3rem;
    font-weight: 700;
    /* white-space: nowrap; */
  }
}

@media (max-width:1024px) {
  .four-cards-parent {
    margin: 0;
    padding: 4rem 1rem;
    background-color: #fdfdfd;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .four-cards-header span {
    text-align: center;
    font-size: 2.5rem;
  }

  .card_1 {
    background-color: #fff;
    border-radius: 10px;
    height: 362px;
    margin: .5rem 29px;
    padding: 30px 20px;
    width: 262px !important;
  }

  button.slick-prev.slick-arrow {
    left: -37px;
    margin-left: 46px;
  }

  button.slick-next.slick-arrow {
    margin-right: 43px;
  }
}

@media (max-width:820px) {
  .four-cards-parent {
    margin: 0;
    padding: 2rem 1rem;
    background-color: #fdfdfd;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .four-cards-header span {
    text-align: center;
    font-size: 2.6rem;
  }

  .card_1 {
    background-color: #fff;
    border-radius: 10px;
    height: 362px;
    margin: .5rem 60px;
    padding: 30px 20px;
    width: 245px !important;
  }

  button.slick-prev.slick-arrow {
    left: -50px;
    margin-left: 46px;
  }

  button.slick-next.slick-arrow {
    margin-right: 43px;
  }
}

@media (max-width:768px) {
  .four-cards-parent {
    margin: 0;
    padding: 2rem 2rem;
    background-color: #fdfdfd;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .four-cards-header span {
    text-align: center;
    font-size: 2.6rem;
  }

  .card_1 {
    background-color: #fff;
    border-radius: 10px;
    height: 362px;
    margin: .5rem 35px;
    padding: 30px 20px;
    width: 245px !important;
  }

  button.slick-prev.slick-arrow {
    left: -50px;
    margin-left: 46px;
  }

  button.slick-next.slick-arrow {
    margin-right: 43px;
  }
}

@media (max-width:450px) {
  .four-cards-parent {
    margin: 0;
    padding: 2rem 1rem;
    background-color: #fdfdfd;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .four-cards-header span {
    text-align: center;
    font-size: 2.5rem;
  }

  .card_1 {
    background-color: #fff;
    border-radius: 10px;
    height: 362px;
    margin: .5rem 47px;
    padding: 30px 20px;
    width: 245px !important;
  }

  button.slick-prev.slick-arrow {
    left: -50px;
    margin-left: 46px;
  }

  button.slick-next.slick-arrow {
    margin-right: 43px;
  }
}

@media (max-width:449px) and (min-width:375px) {
  .card_1 {
    background-color: #fff;
    border-radius: 10px;
    height: 362px;
    margin: .5rem 30px;
    padding: 30px 20px;
    width: 245px !important;
  }
}

@media (max-width:376px) {
  .four-cards-parent {
    margin: 0;
    padding: 2rem 1rem;
    background-color: #fdfdfd;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .four-cards-header span {
    text-align: center;
    font-size: 2rem;
  }

  .card_1 {
    background-color: #fff;
    border-radius: 10px;
    height: 362px;
    margin: .5rem 17px;
    padding: 30px 20px;
    width: 245px !important;
  }

  button.slick-prev.slick-arrow {
    left: -50px;
    margin-left: 46px;
  }

  button.slick-next.slick-arrow {
    margin-right: 43px;
  }
}
@media (max-width:375px) and (min-width:320px) {
  .card_1 {
    background-color: #fff;
    border-radius: 10px;
    height: 362px;
    margin: .5rem 8px;
    padding: 30px 20px;
    width: 245px !important;
  }
}
@media (max-width:321px) {
  .four-cards-parent {
    margin: 0;
    padding: 2rem 1rem;
    background-color: #fdfdfd;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .four-cards-header span {
    text-align: center;
    font-size: 2rem;
  }

  .card_1 {
    background-color: #fff;
    border-radius: 10px;
    height: 362px;
    margin: .5rem 4px;
    padding: 30px 20px;
    width: 217px !important;
  }

  button.slick-prev.slick-arrow {
    left: -50px;
    margin-left: 46px;
  }

  button.slick-next.slick-arrow {
    margin-right: 43px;
  }
}