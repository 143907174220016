@font-face {
    font-family: Popins-Regular;
    src: url('../assets/WONO_images/img/fonts/Poppins-Regular.ttf');
}

.map-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    padding: 2rem;

}

.map-grid {
    height: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.address {
    padding: 1rem 2rem 1rem 2rem;
}

.contact-address {
    font-size: 14px;
    font-family: Popins-Regular;
}

.contact-about-us {
    padding-left: 7rem ;
    padding-right: 7rem ;
}

@media (max-width: 1024px) and (min-width:920px) {
    div.map-container.contact-about-us {
        padding-left: 3rem;
        padding-right: 3rem;

    }

    .map-container {
        grid-template-columns: 1fr;

    }


    .contact-about-us {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    section#contact.contact.contact-about-us {
        padding-left: 3rem;
        padding-right: 3rem;
    }

}

@media (max-width: 820px) {
    .contact-about-us {
        padding-left: 1rem;
        padding-right: 1rem;
    }

}





/* New Styles */

/* Display PC layout on larger screens (e.g., desktop) */
/* @media (min-width: 768px) {

    .wono-text-spacing {
        padding-left: 7rem;
        padding-right: 7rem;
    }
} */

@media (max-width: 768px) {
    .map-container {
        grid-template-columns: 1fr;
    }

    .address {
        padding: 1rem 1rem 1rem 1rem;
    }
    div.map-container.contact-about-us {
        padding-left: 2rem;
        padding-right: 2rem;

    }

    .map-container {
        grid-template-columns: 1fr;

    }


    .contact-about-us {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    section#contact.contact.contact-about-us {
        padding-left: 1rem;
        padding-right: 1rem;
    }

}

/* Display mobile layout on smaller screens */
@media (max-width: 767px) {
    /* .mobile-screen {
        display: block;
     
    } */

    .contact-about-us {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}