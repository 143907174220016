/* .product-page-master{
    padding: 2rem 7rem;
} */

@font-face {
    font-family: Popins-Regular;
    src: url('../assets/WONO_images/img/fonts/Poppins-Regular.ttf');
}

/* .blut-tick-img {
    width: 200px;
    height: 200px;
} */

.product-page-section {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    padding: 4rem 7rem 4rem 7rem;
}

.product-page-master * {
    font-family: Popins-Regular;
}

.product-page {
    background-color: black;
    color: white;
    padding: 0 0rem;
}

.product-page * {
    color: white;
    font-family: Popins-Regular;
}

.product-page-grid {
    display: grid;
    grid-template-columns: 23% 1fr;
    column-gap: 15.9rem;
    padding: 0 0rem;
}



.product-page-grid-item h1 {
    text-transform: uppercase;
    margin-bottom: 2rem;
    font-weight: normal;
    font-family: Popins-Medium !important;
    ;
}

.product-page-feature div {
    margin: 1rem 0 1rem 0;
}

.product-page-features img {
    padding-right: 1rem;
}

.product-page-features span {
    font-size: 1.3rem;
}

.product-page-image-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding-bottom: 2rem;
}

.product-page-image-container img {
    width: 100%;
    height: 100%;

    object-fit: contain;
    object-position: center;
}

.product-page-image-mobile {
    display: none;
}

.product-page-update-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(129, 129, 129);
}

.product-page-update-text * {
    color: rgb(129, 129, 129);
}

.product-page-button-space {
    width: 100%;
}

.product-page-button {
    padding: 0.5rem 2rem;
    background-color: white;
    color: black;
    border-radius: 30px;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 2rem;
}



/* perks */
.product-page-perks-section {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-page-perks {
    padding: 4rem 7rem;
}

.product-page-perks-grid {
    display: grid;
    grid-template-columns: 40% 60%;
    padding: 0 0.4rem;
}

.product-page-perks-grid-1 {
    display: flex;
    align-items: start;
    justify-content: start;
}

.product-page-perks-grid-1 span {
    font-weight: normal;
    font-family: Popins-Medium !important;
    ;
    font-size: 2rem;
}

.product-page-perks-grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}

.product-page-perks-grid-2-item-layout {
    display: grid;
    grid-template-columns: 10% 1fr;
}

.product-page-perks-grid-2-item-layout i {
    height: 100%;
    padding: 0.3rem 0rem 0 0rem;
}

.product-page-perks-description h4 {
    margin-bottom: 1rem;
    font-size: 1.1rem;
    font-weight: normal;
    line-break: strict;
    font-family: Popins-Semibold !important;
    ;
}

.product-page-perks-description span {
    font-size: 1rem;
    letter-spacing: 1px;
}

.product-page-perks-description {
    margin-bottom: 1rem;
}

.product-page-reccomendations-section {
    background-color: rgb(233, 249, 255);
}

.product-page-reccomendations {
    padding: 4rem 7.4rem;
}

.product-page-reccomendations-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.product-page-reccomendations-grid-image {
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    overflow: hidden;
}

.product-page-reccomendations-grid-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.2s ease;
}

.product-page-reccomendations-grid-image img:hover {
    transform: scale(1.2);
}

.product-page-reccomendations-header {
    margin-bottom: 2rem;
}

.product-page-reccomendations-header h2 {
    margin: 0;
    font-weight: normal;
    font-family: Popins-Medium !important;
    ;
}

.product-page-build-section {
    background-color: #0093E9;
    background-image: linear-gradient(0deg, #106df6, #aae5ff);
}

.product-page-build-section * {
    color: black;
}

.product-page-build {
    padding: 4rem 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.product-page-build span {
    font-size: 4.15rem;
    padding: 0rem 0rem 2rem 0rem;
    text-align: center;
    font-weight: normal;
    font-family: Popins-Medium !important;
    ;
}

.product-page-build-button-space {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.product-page-build-button-space button {
    background-color: black;
    color: white;
    border: none;
    margin: 0 16px;
    width: 10%;
}

.wonoColor {
    color: #0AA9EF;
}

/* for 4k screen */
@media (min-width: 2550px) {
    .product-page-build span {
        font-size: 4.2rem;
        padding: 0rem 0rem 2rem 0rem;
        text-align: center;
        font-weight: normal;
    }

    .product-page-grid {
        display: grid;
        grid-template-columns: 23% 1fr;
        column-gap: 81.8rem;
        padding: 0 0rem;
    }

    .wonoColor {
        color: #0AA9EF;
    }
}

/* below 4k screen  */

@media (max-width: 2548px) {
    /* .capital-last-button-section h2 {
        color: #212529;
        font-family: Popins-Regular;
        font-size: 6.5rem;
        font-weight: normal;
        padding-bottom: 2rem;
    } */

    .wonoColor {
        color: #0AA9EF;
    }
}

/* for sir's screen */

@media (max-width:1440px) {

    .wonoColor {
        color: #0AA9EF;
    }

    .product-page-build span {
        font-size: 3.85rem;
        padding: 0rem 0rem 2rem 0rem;
        text-align: center;
        font-weight: normal;
    }

    .product-page {
        background-color: black;
        color: white;
        padding: 0 0rem;
    }

    .product-page-section {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
        padding: 4rem 0rem 4rem 0rem;
    }

    .product-page-grid {
        display: grid;
        grid-template-columns: 23% 1fr;
        column-gap: 11rem;
        padding: 0 7rem;
    }

    .product-page-button {
        padding: 1rem 2rem;
        background-color: white;
        color: black;
        border-radius: 30px;
        width: 100%;
        justify-content: space-around;
        margin-bottom: 2rem;
    }

    .product-page-perks-grid {
        display: grid;
        grid-template-columns: 40% 60%;
        padding: 0 0.4rem;
    }
}

@media (max-width:1024px) {

    .wonoColor {
        color: #0AA9EF;
    }

    .product-page {
        background-color: black;
        color: white;
        padding: 0 4rem;
    }

    .product-page-grid {
        display: grid;
        grid-template-columns: 26% 1fr;
        column-gap: 3rem;
        padding: 0;
    }

    .product-page-grid-item h1 {
        text-transform: uppercase;
        margin-bottom: 2rem;
        font-weight: normal;
        font-size: 2.5rem;
    }

    .product-page-features span {
        font-size: 1.1rem;
    }

    .product-page-update-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: rgb(129, 129, 129);
    }

    .product-page-update-text * {
        color: rgb(129, 129, 129);
        text-align: center;
    }

    .product-page-image-container {
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    .product-page-perks {
        padding: 4rem 3.6rem;
    }

    .product-page-perks-grid {
        display: grid;
        grid-template-columns: 40% 60%;
        column-gap: 1rem;
        padding: 0;
    }

    .product-page-perks-grid-1 span {
        font-weight: normal;
        font-size: 1.7rem;
    }

    .product-page-perks-description h4 {
        margin-bottom: 1rem;
        font-size: 1.3rem;
        font-weight: normal;
        line-break: strict;
    }

    .product-page-perks-description span {
        font-size: 1rem;
        letter-spacing: 1px;
    }

    .product-page-reccomendations {
        padding: 4rem 3.6rem;
    }

    .product-page-perks-grid-2-item-layout i {
        height: 100%;
        padding: 0.1rem 0rem 0 0rem;
    }

    .product-page-reccomendations-header h2 {
        margin: 0;
        font-weight: normal;
        font-size: 2.5rem;
    }

    .product-page-reccomendations-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .product-page-reccomendations-grid-image {
        width: 100%;
        height: auto;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
        overflow: hidden;
    }

    .product-page-build {
        padding: 2rem 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .product-page-build span {
        font-size: 3.127rem;
        padding: 0rem 0 2rem 0;
        text-align: center;
        font-weight: normal;
    }

    .product-page-build-button-space button {
        background-color: black;
        color: white;
        border: none;
        margin: 0 16px;
        width: 20%;
    }

}

@media (max-width:820px) {

    .wonoColor {
        color: #0AA9EF;
    }

    .product-page-section {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
    }

    .product-page-master * {
        font-family: Popins-Regular;
    }

    .product-page {
        background-color: black;
        color: white;
        padding: 1rem;
    }

    .product-page * {
        color: white;
        font-family: Popins-Regular;
    }

    .product-page-grid {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .product-page-grid-item {
        padding-left: 0.6rem;
    }

    .product-page-grid-item h1 {
        text-transform: uppercase;
        margin-bottom: 2rem;
        font-weight: normal;
        text-align: start;
        padding-left: 0;

    }

    .product-page-feature {
        margin: 1rem 0 1rem 0;
    }

    .product-page-feature div {
        margin: 1rem 0 1rem 0;
        padding-left: 0;
    }

    .product-page-features img {
        padding-right: 1rem;
    }

    .product-page-image-mobile {
        display: contents;
    }

    .product-page-features span {
        font-size: 1.3rem;
    }

    .product-page-image-container {
        width: 100%;
        overflow: hidden;
        padding: 0;
    }

    .product-page-image-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .product-page-update-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: rgb(129, 129, 129);
        padding-right: 2rem;
        text-align: center;
    }

    .product-page-update-text * {
        color: rgb(129, 129, 129);
    }

    .product-page-button-space {
        width: 100%;
        text-align: center;
    }

    .product-page-button {
        padding: 1rem 2rem;
        background-color: white;
        color: black;
        border-radius: 30px;
        width: 80%;
        justify-content: space-around;
        margin-bottom: 2rem;
        margin-left: 2rem;
    }



    /* perks */
    .product-page-perks-section {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-page-perks {
        padding: 2rem 2rem;
    }

    .product-page-perks-grid {
        display: grid;
        grid-template-columns: 1fr;
    }

    .product-page-perks-grid-1 {
        display: flex;
        align-items: start;
        justify-content: start;
    }

    .product-page-perks-grid-1 span {
        font-weight: normal;
        font-size: 1.7rem;
        padding-bottom: 2rem;
    }

    .product-page-perks-grid-2 {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .product-page-perks-grid-2-item-layout {
        display: grid;
        grid-template-columns: 4% 1fr;
    }

    .product-page-perks-grid-2-item-layout i {
        height: 100%;
        padding: 0.3rem 0rem 0 0rem;
    }

    .product-page-perks-description h4 {
        margin-bottom: 1rem;
        font-size: 1.2rem;
        font-weight: normal;
    }

    .product-page-perks-description span {
        font-size: 1rem;
    }

    .product-page-perks-description {
        margin-bottom: 1rem;
    }

    .product-page-reccomendations-section {
        background-color: rgb(233, 249, 255);
    }

    .product-page-reccomendations {
        padding: 2rem;
    }

    .product-page-reccomendations-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .product-page-reccomendations-grid-image {
        width: 100%;
        height: 100%;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
        overflow: hidden;
    }

    .product-page-reccomendations-grid-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.2s ease;
    }

    .product-page-reccomendations-grid-image img:hover {
        transform: scale(1.2);
    }

    .product-page-reccomendations-header {
        margin-bottom: 4rem;
    }

    .product-page-build-section {
        background-color: #0093E9;
        background-image: linear-gradient(0deg, #106df6, #aae5ff);
    }

    .product-page-build-section * {
        color: black;
    }

    .product-page-build {
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .product-page-build span {
        font-size: 2rem;
        padding: 0rem 0rem 2rem 0rem;
        text-align: center;
        font-weight: normal;
    }

    .product-page-build-button-space {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .product-page-build-button-space button {
        background-color: black;
        color: white;
        border: none;
        margin: 0 16px;
        width: 50%;
    }
}

@media (max-width:768px) {

    .wonoColor {
        color: #0AA9EF;
    }

    .product-page-section {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
        padding: 0rem 0rem;
    }

    .product-page-master * {
        font-family: Popins-Regular;
    }

    .product-page {
        background-color: black;
        color: white;
        padding: 2rem 1rem;
    }

    .product-page * {
        color: white;
        font-family: Popins-Regular;
    }

    .product-page-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .product-page-grid-item {
        padding-left: 0.6rem;
    }

    .product-page-grid-item h1 {
        text-transform: uppercase;
        margin-bottom: 2rem;
        font-weight: normal;
        text-align: start;
        font-size: 1.5rem;
    }

    .product-page-feature {
        margin: 1rem 0 1rem 0;
    }

    .product-page-feature div {
        margin: 1rem 0 1rem 0;

    }

    .product-page-features img {
        padding-right: 1rem;
    }

    .product-page-image-mobile {
        display: contents;
    }

    .product-page-features span {
        font-size: 1rem;
    }

    .product-page-image-container {
        width: 100%;
        overflow: hidden;
        padding: 0 1rem;
    }

    .product-page-image-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .product-page-update-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: rgb(129, 129, 129);
        padding-right: 2rem;
        text-align: center;
    }

    .product-page-update-text * {
        color: rgb(129, 129, 129);
        font-size: 0.7rem;
    }

    .product-page-button-space {
        width: 100%;
        text-align: center;
    }

    .product-page-button {
        padding: 0.7rem 2rem;
        background-color: white;
        color: black;
        border-radius: 30px;
        width: 60%;
        justify-content: space-around;
        margin-bottom: 2rem;
        margin-left: 2rem;
    }



    /* perks */
    .product-page-perks-section {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-page-perks {
        padding: 2rem 1.7rem;
    }

    .product-page-perks-grid {
        display: grid;
        grid-template-columns: 1fr;
    }

    .product-page-perks-grid-1 {
        display: flex;
        align-items: start;
        justify-content: start;
    }

    .product-page-perks-grid-1 span {
        font-weight: normal;
        font-size: 1.7rem;
        padding-bottom: 2rem;
    }

    .product-page-perks-grid-2 {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .product-page-perks-grid-2-item-layout {
        display: grid;
        grid-template-columns: 10% 1fr;
    }

    .product-page-perks-grid-2-item-layout i {
        height: 100%;
        padding: 0.3rem 0rem 0 0rem;
    }

    .product-page-perks-description h4 {
        margin-bottom: 1rem;
        font-size: 1.2rem;
        font-weight: normal;
    }

    .product-page-perks-description span {
        font-size: 1rem;
    }

    .product-page-perks-description {
        margin-bottom: 1rem;
    }

    .product-page-reccomendations-section {
        background-color: rgb(233, 249, 255);
    }

    .product-page-reccomendations {
        padding: 2rem 1.7rem;
    }

    .product-page-reccomendations-header h2 {
        margin: 0;
        font-weight: normal;
        font-size: 1.8rem;
    }

    .product-page-reccomendations-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .product-page-reccomendations-grid-image {
        width: 100%;
        height: auto;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
        overflow: hidden;
    }

    .product-page-reccomendations-grid-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.2s ease;
    }

    .product-page-reccomendations-grid-image img:hover {
        transform: scale(1.2);
    }

    .product-page-reccomendations-header {
        margin-bottom: 4rem;
    }

    .themes-view-button button {
        width: 25% !important;
        padding: 0.7rem 2rem;
    }

    .product-page-build-section {
        background-color: #0093E9;
        background-image: linear-gradient(0deg, #106df6, #aae5ff);
    }

    .product-page-build-section * {
        color: black;
    }

    .product-page-build {
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .product-page-build span {
        font-size: 2rem;
        padding: 0rem 0rem 2rem 0rem;
        text-align: center;
        font-weight: normal;
    }

    .product-page-build-button-space {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .product-page-build-button-space button {
        background-color: black;
        color: white;
        border: none;
        margin: 0 16px;
        width: 25%;
    }
}

@media (max-width:450px) {

    .wonoColor {
        color: #0AA9EF;
    }

    .product-page-section {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
        padding: 0rem 0rem;
    }

    .product-page-master * {
        font-family: Popins-Regular;
    }

    .product-page {
        background-color: black;
        color: white;
        padding: 2rem 1rem;
    }

    .product-page * {
        /* color: white; */
        font-family: Popins-Regular;
    }

    .product-page-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .product-page-grid-item {
        padding-left: 0.6rem;
    }

    .product-page-grid-item h1 {
        text-transform: uppercase;
        margin-bottom: 2rem;
        font-weight: normal;
        text-align: start;
        font-size: 1.5rem;
    }

    .product-page-feature {
        margin: 1rem 0 1rem 0;
    }

    .product-page-feature div {
        margin: 1rem 0 1rem 0;

    }

    .product-page-features img {
        padding-right: 1rem;
    }

    .product-page-image-mobile {
        display: contents;
    }

    .product-page-features span {
        font-size: 1rem;
    }

    .product-page-image-container {
        width: 100%;
        overflow: hidden;
        padding: 0 1rem;
    }

    .product-page-image-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .product-page-update-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: rgb(129, 129, 129);
        padding-right: 2rem;
        text-align: center;
    }

    .product-page-update-text * {
        color: rgb(129, 129, 129);
        font-size: 0.7rem;
    }

    .product-page-button-space {
        width: 100%;
        text-align: center;
    }

    .product-page-button {
        padding: 0.7rem 2rem;
        background-color: white;
        color: black;
        border-radius: 30px;
        width: 60%;
        justify-content: space-around;
        margin-bottom: 2rem;
        margin-left: 2rem;
    }



    /* perks */
    .product-page-perks-section {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-page-perks {
        padding: 2rem 1.7rem;
    }

    .product-page-perks-grid {
        display: grid;
        grid-template-columns: 1fr;
    }

    .product-page-perks-grid-1 {
        display: flex;
        align-items: start;
        justify-content: start;
    }

    .product-page-perks-grid-1 span {
        font-weight: normal;
        font-size: 1.7rem;
        padding-bottom: 2rem;
    }

    .product-page-perks-grid-2 {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .product-page-perks-grid-2-item-layout {
        display: grid;
        grid-template-columns: 10% 1fr;
    }

    .product-page-perks-grid-2-item-layout i {
        height: 100%;
        padding: 0.3rem 0rem 0 0rem;
    }

    .product-page-perks-description h4 {
        margin-bottom: 1rem;
        font-size: 1.2rem;
        font-weight: normal;
    }

    .product-page-perks-description span {
        font-size: 1rem;
    }

    .product-page-perks-description {
        margin-bottom: 1rem;
    }

    .product-page-reccomendations-section {
        background-color: rgb(233, 249, 255);
    }

    .product-page-reccomendations {
        padding: 2rem 1.7rem;
    }

    .product-page-reccomendations-header h2 {
        margin: 0;
        font-weight: normal;
        font-size: 1.8rem;
        font-family: Popins-Medium !important;
        ;
    }

    .product-page-reccomendations-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .product-page-reccomendations-grid-image {
        width: 100%;
        height: auto;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
        overflow: hidden;
    }

    .product-page-reccomendations-grid-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.2s ease;
    }

    .product-page-reccomendations-grid-image img:hover {
        transform: scale(1.2);
    }

    .product-page-reccomendations-header {
        margin-bottom: 4rem;
    }

    .themes-view-button button {
        width: 50% !important;
        padding: 0.7rem 2rem;
    }

    .product-page-build-section {
        background-color: #0093E9;
        background-image: linear-gradient(0deg, #106df6, #aae5ff);
    }

    .product-page-build-section * {
        color: black;
    }

    .product-page-build {
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .product-page-build span {
        font-size: 2rem;
        padding: 0rem 0rem 2rem 0rem;
        text-align: center;
        font-weight: normal;
    }

    .product-page-build-button-space {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .product-page-build-button-space button {
        background-color: black;
        color: white;
        border: none;
        margin: 0 16px;
        width: 44%;
    }
}

@media (max-width:326px) {

    .wonoColor {
        color: #0AA9EF;
    }

    .product-page-section {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
        padding: 0rem 0rem;
    }

    .product-page-master * {
        font-family: Popins-Regular;
    }

    .product-page {
        background-color: black;
        color: white;
        padding: 2rem 1rem;
    }

    .product-page * {
        color: white;
        font-family: Popins-Regular;
    }

    .product-page-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .product-page-grid-item {
        padding-left: 0.6rem;
    }

    .product-page-grid-item h1 {
        text-transform: uppercase;
        margin-bottom: 2rem;
        font-weight: normal;
        text-align: start;
        font-size: 1.5rem;
    }

    .product-page-feature {
        margin: 1rem 0 1rem 0;
    }

    .product-page-feature div {
        margin: 1rem 0 1rem 0;

    }

    .product-page-features img {
        padding-right: 1rem;
    }

    .product-page-image-mobile {
        display: contents;
    }

    .product-page-features span {
        font-size: 1rem;
    }

    .product-page-image-container {
        width: 100%;
        overflow: hidden;
        padding: 0 1rem;
    }

    .product-page-image-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .product-page-update-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: rgb(129, 129, 129);
        padding-right: 2rem;
        text-align: center;
    }

    .product-page-update-text * {
        color: rgb(129, 129, 129);
        font-size: 0.7rem;
    }

    .product-page-button-space {
        width: 100%;
        text-align: center;
    }

    .product-page-button {
        padding: 0.7rem 2rem;
        background-color: white;
        color: black;
        border-radius: 30px;
        width: 60%;
        justify-content: space-around;
        margin-bottom: 2rem;
        margin-left: 2rem;
    }



    /* perks */
    .product-page-perks-section {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-page-perks {
        padding: 2rem 1.7rem;
    }

    .product-page-perks-grid {
        display: grid;
        grid-template-columns: 1fr;
    }

    .product-page-perks-grid-1 {
        display: flex;
        align-items: start;
        justify-content: start;
    }

    .product-page-perks-grid-1 span {
        font-weight: normal;
        font-size: 1.7rem;
        padding-bottom: 2rem;
    }

    .product-page-perks-grid-2 {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .product-page-perks-grid-2-item-layout {
        display: grid;
        grid-template-columns: 10% 1fr;
    }

    .product-page-perks-grid-2-item-layout i {
        height: 100%;
        padding: 0.3rem 0rem 0 0rem;
    }

    .product-page-perks-description h4 {
        margin-bottom: 1rem;
        font-size: 1.2rem;
        font-weight: normal;
    }

    .product-page-perks-description span {
        font-size: 1rem;
    }

    .product-page-perks-description {
        margin-bottom: 1rem;
    }

    .product-page-reccomendations-section {
        background-color: rgb(233, 249, 255);
    }

    .product-page-reccomendations {
        padding: 2rem 1.7rem;
    }

    .product-page-reccomendations-header h2 {
        margin: 0;
        font-weight: normal;
        font-size: 1.8rem;
    }

    .product-page-reccomendations-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .product-page-reccomendations-grid-image {
        width: 100%;
        height: auto;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
        overflow: hidden;
    }

    .product-page-reccomendations-grid-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.2s ease;
    }

    .product-page-reccomendations-grid-image img:hover {
        transform: scale(1.2);
    }

    .product-page-reccomendations-header {
        margin-bottom: 4rem;
    }

    .themes-view-button button {
        width: 50% !important;
        padding: 0.7rem 2rem;
    }

    .product-page-build-section {
        background-color: #0093E9;
        background-image: linear-gradient(0deg, #106df6, #aae5ff);
    }

    .product-page-build-section * {
        color: black;
    }

    .product-page-build {
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .product-page-build span {
        font-size: 2rem;
        padding: 0rem 0rem 2rem 0rem;
        text-align: center;
        font-weight: normal;
    }

    .product-page-build-button-space {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .product-page-build-button-space button {
        background-color: black;
        color: white;
        border: none;
        margin: 0 16px;
        width: 50%;
    }
}
@media (max-width:326px) and (min-width:200px){
    .product-page-button {
        padding: 0.7rem 1.5rem;
        background-color: white;
        color: black;
        border-radius: 30px;
        width: 60%;
        justify-content: space-around;
        margin-bottom: 2rem;
        margin-left: 2rem;
    }
}