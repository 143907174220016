@font-face {
    font-family: Popins-Semibold;
    src: url('../assets/WONO_images/img/fonts/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: Popins-Regular;
    src: url('../assets/WONO_images/img/fonts/Poppins-Regular.ttf');
}

.oneRemPaddingLeft {
    padding-left: 1rem;
}

.privSect2 {
    /* padding-bottom: 1rem; */
    padding-bottom: 0;
    font-family: Popins-Regular;
}

.privSect2 h4 {
    margin-bottom: 0;
    font-family: Popins-Regular;
    font-weight: bold;
}

.privSect2 p {
    font-size: 1rem;
    margin-bottom: 1.8rem;
    font-family: Popins-Regular;
}

.WONOBLUE {
    color: #0AA9EF;
}

.privacy-container {
    font-family: Popins-Semibold;
    padding: 3rem;
}

.privacy-section>ul>li b {
    font-family: Popins-Semibold;
}

.privacy-section h4 {
    font-family: Popins-Semibold;
}

.privacy-container div {
    margin: 1rem 0 1rem 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #364D59;
}

@media (min-width: 1025px) {

    .paddingForTab {
        padding-left: 7rem !important;
        padding-right: 7rem !important;
    }


}


@media (min-width: 768px) and (max-width: 1024px) {

    .paddingForTab {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }

    /* .wono-text-spacing {
        padding-left: 3rem;
        padding-right: 3rem;
    } */

    /* .privacy-container {
        padding: 1.2rem;
    } */
}

@media (max-width:768px) {
    .privacy-container {
        padding: 0.8rem;
    }
}


/* Display PC layout on larger screens (e.g., desktop) */
@media (min-width: 768px) {
    /* .pc-screen {
        display: flex;
      
    } */

    .wono-text-spacing {
        /* padding-left: 7rem;
        padding-right: 7rem; */
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }
}

/* Display mobile layout on smaller screens */
@media (max-width: 767px) {
    /* .mobile-screen {
        display: block;
     
    } */

    .wono-text-spacing {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .terms-title {
        font-size: 1.4rem;
    }

    .terms-title img {
        position: absolute;
        top: 62%;
        left: 14%;
        width: 70%;
        height: 20%;
    }


}


/* Display mobile layout on smaller screens */
@media (max-width: 325px) {
    /* .mobile-screen {
        display: block;
     
    } */

    .wono-text-spacing {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .terms-title {
        font-size: 1rem;
    }

    .terms-title img {
        position: absolute;
        top: 62%;
        left: 14%;
        width: 70%;
        height: 20%;
    }


}