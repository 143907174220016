@font-face {
    font-family: Popins-Medium;
    src: url('../assets/WONO_images/img/fonts/Poppins-Medium.ttf');
}

@font-face {
    font-family: Popins-Regular;
    src: url('../assets/WONO_images/img/fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: Popins-Black;
    src: url('../assets/WONO_images/img/fonts/Poppins-Black.ttf');
}

.capital-master * {
    font-family: Popins-Regular;
}

.headingContainer {

    /* height: 350px; */
    height: 270px;
    position: relative;
    padding: 4rem 7rem;
}

.capital-heading {
    width: 100%;
    text-align: center;
    font-family: Popins-Medium;
    font-size: 40px;
    font-weight: normal;

}

.capital-heading-sub {
    width: 100%;
    text-align: center;
    font-family: Popins-Medium;
    font-size: 40px;
    font-weight: normal;

}

.connect-with-us-btn {
    position: absolute;
    right: 6rem;
    background-color: black;
    color: white;
    border-radius: 30px;
    padding: 0.5rem 2rem;
    font-family: Popins-Regular;

}

.capital-cards-section {
    background-color: black;
    padding-left: 8rem;
    padding-right: 8rem;
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    position: relative;
    gap: 10px;
    justify-content: space-evenly;
}

.cards-with-text h3 {
    color: white;
    font-size: 20px;
    font-family: Popins-Regular;
    text-align: left;
    text-transform: uppercase;
}

.cards-with-text p {
    color: white;
    font-family: Popins-Regular;
    position: relative;
    margin-top: 0;
    font-size: 15px;
    padding: 1rem 0;
    padding-left: 5px;

}

.cards-with-text p span {
    color: white;
}

.capital-img {
    max-width: 350px;
    border-radius: 10px;
    height: 400px;
    overflow-x: hidden;
}

.capital-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s ease;
}

.capital-img img:hover {
    width: 100%;
}

.plus-icon {
    color: white;
    background-color: rgba(0, 0, 0, 0.2);
    position: relative;
    bottom: 60px;
    font-size: 35px;
    z-index: 1;
    border-radius: 50px;
    height: 50px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    left: 80%;
    width: 50px;
    display: none;

}

.plus-icon:hover {
    color: white;
    background-color: black;
    cursor: pointer;
}

.capital-big-text {
    font-family: Popins-Regular;
    /* padding: 5% 4rem 5% 4rem; */
    padding: 4rem 7.3rem;

}

.capital-big-text h1 {
    font-size: 54px;
    font-family: Popins-Regular;
    line-height: 4 rem;
}

.three-card-images {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 6px;
    padding: 0.5rem;
}

.three-card-images .card-img-1 {
    width: 100%;
    height: 400px;
    overflow: hidden;
    border-radius: 10px;
}

.card-img-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.big-end-image {
    width: 100%;
    height: 90%;
    position: relative;
    background-color: black;
}

.big-end-image img {
    height: 35rem;
    width: 100%;

}

/* .back-wrapper-dashboard-img{
    width: 100%;
    height: 200px;
    background-color: black;
    z-index: 2;
    position: absolute;
} */
.capital-last-button-section {
    position: relative;
    background-color: #0093E9;
    background-image: linear-gradient(0deg, #106df6, #aae5ff);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 4rem 7rem 4rem 7rem;
    border-bottom: 1px solid rgba(13, 110, 253);

}

/* .capital-last-button-section h2 {
    color: #212529;
    font-family: Popins-Regular;
    font-size: 6.5rem;
    font-weight: bold;
    padding-bottom: 2rem;
} */


/* for 4k screen */
@media (min-width: 2550px) {
    .capital-last-button-section h2 {
        color: #212529;
        font-family: Popins-Regular;
        font-size: 11.7rem;
        font-weight: bold;
        padding-bottom: 2rem;
    }

    .grow-your-business-color p {
        font-size: 2rem;
    }
}

/* below 4k screen  */

@media (max-width: 2548px) {
    .capital-last-button-section h2 {
        color: #212529;
        font-family: Popins-Regular;
        font-size: 6.49rem;
        font-weight: bold;
        padding-bottom: 2rem;
    }
}

/* for sir's screen */

@media (max-width:1440px) {
    .capital-last-button-section h2 {
        color: #212529;
        font-family: Popins-Regular;
        font-size: 6rem;
        font-weight: bold;
        padding-bottom: 2rem;
    }
}

.Connect-with-us-today-btn {
    width: 400px;
    height: 50px;
    background-color: black;
    color: white;
    font-family: Popins-Regular;


}

.frictionless-heading-with-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 4rem 7rem;
}

.frictionless-heading-with-btns h1 {
    font-size: 57px;
    font-family: popins-Regular;
    color: white;
    text-align: center;
}

.frictionless-btns {
    margin-top: 2%;
}

.capital-start-for-free-btn {
    margin-top: 5%;
    width: 150px;
    height: 50px;
    border-radius: 5px;
    font-family: popins-Regular;
}

.btn2 {
    border-color: 1px solid white;
    background-color: black;
    color: white;
}

.frictionless-heading-with-btns h1 span {
    color: #0AA9EF
}

.big-end-dashboard-img {
    padding: 0 8% 0 8%;
}

.hero_animation-container {
    position: relative;
    margin-left: 10px;
    font-family: Popins-Medium !important;
}

.hero__animation {
    fill: none;
    stroke: #0AA9EF;
    stroke-width: 4;
    stroke-miterlimit: 0;
    stroke-dasharray: 1650;
    stroke-linecap: round;
    animation: animateScribble 2s ease-out .5s forwards;
    height: 100px;
    left: -3.3rem;
    opacity: 0;
    position: absolute;
    top: -7px;
    transform-origin: left;
    z-index: -1;
    padding-bottom: 18px;
    padding-left: 40px;



    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media not all and (min-resolution: .001dpcm) {
    @supports (-webkit-appearance: none) {
        .hero__animation {
            fill: none;
            stroke: #0AA9EF;
            stroke-width: 4;
            stroke-miterlimit: 0;
            stroke-dasharray: 1650;
            stroke-linecap: round;
            animation: animateScribble 2s ease-out .5s forwards;
            height: 100px;
            left: -3.3rem;
            opacity: 0;
            position: absolute;
            top: -7px;
            transform-origin: left;
            z-index: -1;
            padding-bottom: 15px;
            padding-left: 40px;

        }
    }
}





@keyframes animateScribble {
    0% {
        stroke-dashoffset: 1650;
        opacity: 1;
        transition: stroke-dashoffset 0s .4s, opacity .4s;
    }

    100% {
        stroke-dashoffset: 0;
        opacity: 1;
        transition: stroke-dashoffset .5s cubic-bezier(.645, .045, .355, 1);
    }
}







/* Responsiveness of cards section */

@media (max-width:1024px) {
    .headingContainer {
        height: 300px;
        padding-right: 3rem;
        padding-left: 3rem;
        position: relative;
    }

    .connect-with-us-btn {
        position: absolute;
        right: 2rem;
    }

    .capital-cards-section {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .capital-big-text {
        font-family: Popins-Regular;
        /* padding: 5% 4rem 5% 4rem; */
        padding: 4rem 3rem;


    }

    .capital-big-text h1 {
        font-size: 2rem;

    }

    .frictionless-heading-with-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 4rem 4rem;
    }

    .big-end-dashboard-img {
        padding: 0 4% 0 4%;
    }

    .capital-last-button-section {
        position: relative;
        background-color: #0093E9;
        background-image: linear-gradient(0deg, #106df6, #aae5ff);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding: 2rem 3rem 2rem 3rem;
        border-bottom: 1px solid rgba(13, 110, 253);
        text-align: center;
    }

    .capital-last-button-section h2 {
        color: black;
        font-family: Popins-Regular;
        font-size: 97px;
        font-weight: bold;
        padding-bottom: 2rem;



    }

    .cards-with-text h3 {
        font-size: 24px;
    }

}


@media (max-width:820px) {
    .capital-cards-section {
        overflow-x: auto;

    }

    .cards-with-text h3 {
        font-size: 16px;
    }


    .headingContainer {
        /* height: 350px; */
        height: 300px;
        position: relative;
        padding: 4rem 0rem;


    }

    .capital-heading {
        position: relative;
        font-size: 24px;
        /* padding-top: 4rem;
        padding-left: 1rem;
        padding-right: 1rem; */
    }

    .connect-with-us-btn {
        right: 34%;
        align-items: flex-start;

    }

    .capital-big-text {
        padding: 4rem 2rem;
    }

    .capital-big-text h1 {
        font-size: 25px;

    }


    .three-card-images {
        flex-wrap: wrap;
        height: 100%;
    }

    /* Green-Round -Marker-heading */
    .hero__animation {
        padding-left: 44px;
        padding-bottom: 9px;
        height: 60px;
        top: -8px;
    }

    /* End */
    /* 4cards section */

    .capital-cards-section {
        padding-left: 2rem;
        padding-right: 2rem;



    }

    .frictionless-heading-with-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 4rem 2rem;
    }

    .frictionless-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        font-size: 15px;
    }

    .big-end-dashboard-img {
        height: 16rem;
    }

    .big-end-image {
        height: 100%;
    }

    .big-end-image img {
        height: 16rem;
        width: 100%;
        padding-bottom: 0;

    }

    .capital-img {
        min-width: 228px;
        /* Adjust to the calculated width */
        width: 100%;
        /* Ensure it scales down for smaller screens */
        height: 200px;
        /* Adjust height to maintain the aspect ratio */
    }

    .plus-icon {
        font-size: 20px;
        height: 30px;
        width: 30px;
    }

    /* end */
    /* 3 Crads section */
    .three-card-images {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        margin-bottom: 0;
        width: 100%;
        padding-bottom: 4rem;
    }

    .card-img-1 {

        width: 100%;
        height: 100%;
        position: relative;
    }

    .card-img-1 img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }


    .frictionless-heading-with-btns h1 {
        font-size: 25px;
    }

    .capital-last-button-section h2 {
        font-size: 3.6rem;
        padding-bottom: 0;
        margin-bottom: 1rem;
    }

    .capital-heading-sub {
        font-size: 26px;
        padding-left: 0;
        padding-right: 0;

    }

    .capital-last-button-section {
        position: relative;
        background-color: #0093E9;
        background-image: linear-gradient(0deg, #106df6, #aae5ff);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding: 4rem 1rem;
        border-bottom: 1px solid rgba(13, 110, 253);

    }
}

@media (max-width:769px) {
    .headingContainer {
        /* height: 350px; */
        height: 300px;
        position: relative;
        padding: 4rem 0rem;


    }

    .connect-with-us-btn {
        right: 40%;
        align-items: flex-start;

    }

}

@media (max-width:768px) {
    .capital-cards-section {
        overflow-x: auto;

    }

    .headingContainer {
        /* height: 350px; */
        height: 300px;
        position: relative;
        padding: 4rem 0rem;


    }

    .capital-heading {
        position: relative;
        font-size: 1.61rem;
    }


    .cards-with-text h3 {
        font-size: 15px;
    }

    .connect-with-us-btn {
        right: 20%;
        align-items: flex-start;

    }

    .capital-big-text {
        padding: 4rem 2rem;
    }

    .capital-big-text h1 {
        font-size: 25px;
        text-align: left;

    }


    .three-card-images {
        flex-wrap: wrap;
        height: 100%;
    }

    /* Green-Round -Marker-heading */
    .hero__animation {
        padding-left: 53px;
        padding-bottom: 9px;
        height: 60px;
        top: -5px;
    }

    /* End */
    /* 4cards section */

    .capital-cards-section {
        padding-left: 2rem;
        padding-right: 2rem;



    }

    .frictionless-heading-with-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 4rem 2rem;
    }

    .frictionless-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 250px;
        font-size: 15px;
    }

    .capital-start-for-free-btn {
        width: 150px;
    }


    .big-end-dashboard-img {
        height: 16rem;
    }

    .big-end-image {
        height: 100%;
    }

    .big-end-image img {
        height: 16rem;
        width: 100%;
        padding-bottom: 0;

    }

    .capital-img {
        min-width: 228px;
        /* Adjust to the calculated width */
        width: 100%;
        /* Ensure it scales down for smaller screens */
        height: 200px;
        /* Adjust height to maintain the aspect ratio */
    }

    .plus-icon {
        font-size: 20px;
        height: 30px;
        width: 30px;
    }

    /* end */
    /* 3 Crads section */
    .three-card-images {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        margin-bottom: 0;
        width: 100%;
        padding-bottom: 4rem;
    }

    .card-img-1 {

        width: 100%;
        height: 100%;
        position: relative;
    }

    .card-img-1 img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }


    .frictionless-heading-with-btns h1 {
        font-size: 25px;
    }

    .capital-last-button-section h2 {
        font-size: 3.4rem;
        padding-bottom: 0;
        /* margin-bottom: 1rem; */
    }

    .capital-heading-sub {
        font-size: 26px;
        padding-left: 0;
        padding-right: 0;

    }

    .capital-last-button-section {
        position: relative;
        background-color: #0093E9;
        background-image: linear-gradient(0deg, #106df6, #aae5ff);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding: 2rem 1rem;
        border-bottom: 1px solid rgba(13, 110, 253);

    }

}

@media (max-width:361px) {

    .capital-heading {
        font-size: 24px;
    }

    .hero__animation {
        padding-left: 49px;
        padding-bottom: 17px;
        height: 66px;
        top: -5px;
    }

    .capital-heading-sub {
        font-size: 24px;
    }
}

@media (max-width:768px) and (min-width:322px) {
    .capital-heading {
        font-size: calc(1.61rem + (2 - 1.6) * ((100vw - 321) / (768 - 321)));
    }
}

@media (max-width:321px) {
    .headingContainer {
        /* height: 350px; */
        height: 300px;
        position: relative;
        padding: 4rem 0rem;


    }

    .connect-with-us-btn {
        right: 20%;
        align-items: flex-start;

    }

    .capital-last-button-section {
        position: relative;
        background-color: #0093E9;
        background-image: linear-gradient(0deg, #106df6, #aae5ff);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding: 2rem 1rem;
        border-bottom: 1px solid rgba(13, 110, 253);

    }

    .capital-last-button-section h2 {
        font-size: 1.8rem;
        padding-bottom: 0;
        /* margin-bottom: 1rem; */
    }

    .frictionless-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 250px;
        font-size: 15px;
        flex-direction: column;
    }

    .capital-heading {
        position: relative;
        font-size: 1.61rem;
    }
}

@media (max-width:376px) {
    .headingContainer {
        height: 263px;
        position: relative;
        padding: 4rem 0rem 2rem;
    }

    .connect-with-us-btn {
        right: 18%;
        align-items: flex-start;

    }

}

@media (max-width:320px) {
    .headingContainer {
        /* height: 350px; */
        height: 263px;
        position: relative;
        padding: 4rem 1rem 2rem;
    }

    .capital-heading {
        font-size: 20px;
    }

    .hero__animation {
        padding-left: 49px;
        padding-bottom: 17px;
        height: 58px;
        top: -5px;
    }

    .capital-heading-sub {
        font-size: 20px;
        padding-left: 0;
        padding-right: 0;
    }

    .connect-with-us-btn {
        right: 15%;
        align-items: flex-start;
    }

}

@media (min-width: 2560px) {
    .capital-img {
        max-width: calc((100vw - 14rem) / 3);

    }
}