@font-face {
    font-family: Popins-Semibold;
    src: url('../assets/WONO_images/img/fonts/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: ComicSansMS;
    src: url('../assets/WONO_images/img/fonts/ComicSansMS.ttf');
}

@font-face {
    font-family: Popins-Regular;
    src: url('../assets/WONO_images/img/fonts/Poppins-Regular.ttf');
}

.oneRemPaddingLeft {
    padding-left: 1rem;
}

.yMargin {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.privSect2 {
    /* padding-bottom: 1rem; */
    padding-bottom: 0;
    font-family: Popins-Regular !important;
}

.privSect2 h4 {
    margin-bottom: 0;
    font-family: Popins-Regular !important;
    font-size: 1.3rem;
    font-weight: bold;
    padding-bottom: 1rem;
}

.privSect2 p {
    font-size: 1rem;
    margin-bottom: 1.8rem;
    font-family: Popins-Regular !important;
}

@media (min-width: 1025px) {

    .paddingForTab {
        padding-left: 7rem !important;
        padding-right: 7rem !important;
    }


}

@media (min-width: 768px) and (max-width: 1024px) {

    .paddingForTab {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }

    /* .wono-text-spacing {
        padding-left: 3rem;
        padding-right: 3rem;
    } */

    /* .privacy-container {
        padding: 1.2rem;
    } */
}

.WONOBLUE {
    color: #0AA9EF;
}

.ComicFont {
    font-family: ComicSansMS;
}

.blue-italics {
    font-style: italic;
    color: #0AA9EF;
}

.privacy-container {
    font-family: Popins-Semibold;
    /* padding: 5rem; */
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.privacy-section>ul>li b {
    font-family: Popins-Semibold;
}

.privacy-section h4 {
    font-family: Popins-Semibold;
}

.privacy-container div {
    margin: 1rem 0 1rem 0;
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
    font-family: Popins-Semibold;
    color: #364D59;
}

.privacy-title {
    font-family: ComicSansMS;
    position: relative;
    width: 100%;
}

.privacy-title img {
    position: absolute;
    top: 54%;
    left: 36%;
    width: 26%;
    height: 23%;
}

@media (max-width:1024px) {
    .privacy-title img {
        position: absolute;
        top: 50%;
        left: 26%;
        width: 47%;
        height: 23%;
    }
}

@media (max-width:768px) {
    .privacy-title img {
        position: absolute;
        top: 50%;
        left: 14%;
        width: 70%;
        height: 23%;
    }
}


@media (max-width:768px) {
    .privacy-container {
        /* padding: 4rem 0.8rem 4rem 0.8rem; */
        padding: 0 0.8rem 0 0.8rem;
    }
}


/* Display PC layout on larger screens (e.g., desktop) */
@media (min-width: 768px) {
    /* .pc-screen {
        display: flex;
      
    } */

    .wono-text-spacing {
        padding-left: 7rem;
        padding-right: 7rem;
    }
}

/* Display mobile layout on smaller screens */
@media (max-width: 767px) {
    /* .mobile-screen {
        display: block;
     
    } */

    .wono-text-spacing {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .privacy-title {
        font-size: 1.6rem;
    }
}

@media (max-width:426px) {
    .privacy-title img {
        position: absolute;
        top: 59%;
        left: 18%;
        width: 63%;
        height: 15%;
    }
}