/* General styling */
/* :root {
    --smaller: .75;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body {
    height: 100%;
    margin: 0;
} */

/* body {
    align-items: center;
    background-color: #ffd54f;
    display: flex;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
} */

.tickerContainer {
    color: #333;
    /* margin: 0 auto; */
    /* margin-top: 10rem; */
    /* height: 100vh; */
    /* height: 20rem; */
    /* height: 22rem; */
    text-align: center;
    align-items: center;
    /* background-color: #ffd54f; */
    /* background-color: #ffd54f; */
    background-color: #0093E9;
    /* background-color: yellow; */
    /* background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%); */
}

.tickerPadding {
    /* padding-top: 3rem; */
    /* height: 25rem; */
    height: 20rem;
    /* height: 70vh; */
}

/* h1 {
    font-weight: normal;
    letter-spacing: .125rem;
    text-transform: uppercase;
} */

#countdown {
    display: flex;
    justify-content: center;
    /* gap: 1em; */
    /* gap: 5em; */
    gap: 2em;
}

.time-unit {
    display: inline-block;
    /* Ensure time units are inline */
    font-size: 1.5em;
    text-transform: uppercase;
}

.time-unit span {
    display: block;
    /* font-size: 4.5rem; */
    font-size: 8.5rem;
}

.emoji {
    display: none;
    padding: 1rem;
}

.emoji span {
    font-size: 4rem;
    padding: 0 .5rem;
}

.sidePadding {
    padding-left: 7rem;
    padding-right: 7rem;
}

.headlineFont {
    font-size: 3.2rem !important;

    font-weight: normal;
    letter-spacing: .125rem;
    text-transform: uppercase;
}


.cont {
    background-color: #0093E9;
}


.colon {
    font-size: 8rem;
    /* Adjust the size to match the time units */
    padding: 0 0.2em;
    /* padding: 0; */
    /* Adjust the spacing around the colons */
    color: #333;
    /* Set the color */
    text-align: center;
}

@media all and (max-width: 768px) {
    /* 
        .headlineFont {
        font-size: calc(1.5rem * var(--smaller));
    } */

    .time-unit {
        font-size: calc(1.125rem * var(--smaller));
    }

    .time-unit span {
        font-size: calc(3.375rem * var(--smaller));
    }

    .sidePadding {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .headlineFont {
        font-size: 1.2rem !important;

        font-weight: normal;
        letter-spacing: .125rem;
        text-transform: uppercase;
    }

    #countdown {
        display: flex;
        justify-content: center;
        /* gap: 1em; */
        /* gap: 0.5rem; */
        gap: 0;
        /* gap: 5em; */
    }

    .time-unit span {
        display: block;
        font-size: 4.5rem;
        /* font-size: 8.5rem; */
    }

    .colon {
        font-size: 4rem;
        /* Adjust the size to match the time units */
        /* padding: 0 0.1em; */
        padding: 0;
        /* Adjust the spacing around the colons */
        color: #333;
        /* Set the color */
        text-align: center;
    }
}