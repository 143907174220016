@font-face {
    font-family: Popins-Regular;
    src: url('../assets/WONO_images/img/fonts/Poppins-Regular.ttf');
}

/* .themePage-master {
    padding: 2rem 7rem;
} */




.themesBtnPadding {
    padding: 2rem 0 0;
}

.themePage-master * {
    font-family: Popins-Regular;
}

.themePage-header {
    padding: 4rem 7.5rem 3rem 7.5rem;
}

.themePage-header h1 {
    font-weight: normal;
    font-size: 4rem;
    margin-bottom: 2rem;
    font-family: Popins-Medium !important;
}

.themePage-header p {
    font-size: 1.51rem;
    font-weight: 400;
}

.themePage-content {}

.themePage-content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding: 2rem 7.5rem 4rem 7.5rem;
    background-color: rgb(240, 240, 240);
}

.themePage-content-header {
    text-align: left;
    padding: 2rem 7.5rem 0 7.5rem;
    background-color: rgb(240, 240, 240);
}

.themePage-content-header h3 {
    margin-bottom: 1rem;
    font-weight: normal;
    font-size: 2.5rem;
    font-family: Popins-Medium !important;
}

.themePage-content-header span {
    padding-bottom: 2rem;
    font-size: 1.8rem;
}



.theme-grid {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.theme-grid img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.2s ease;
}

.theme-grid img:hover {
    transform: scale(1.2);
    cursor: pointer;
}

.themePage-featured {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 7.5rem;
}

.themePage-featured-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* padding: 2rem; */
    gap: 1rem;
    border-radius: 10px;
    /* height: 100%; */
}

/* .themePage-featured-grid-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
} */

/* .themePage-featured-header h1 {
    font-size: 4rem;
    text-align: left;
    padding-right: 12rem;
    margin: 2rem 0
} */

.themePage-featured-content {
    width: 100%;
    margin-bottom: 2rem;
    padding-left: 3rem;
}

.themePage-featured-content ul {
    margin-bottom: 0;
    font-size: 1rem;
}

.themePage-featured-content ul li {
    margin-bottom: 0;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
}


.themePage-featured-button {
    display: flex;
    justify-content: center;
    align-items: start;
    width: 100%;
    padding: 2rem 0 0 0;
}

.themePage-featured-button button {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    margin-left: 0;
   
}

.themePage-featured-grid-2 {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: rgb(255, 255, 255);
    padding: 0 0;
}

.themePage-featured-grid-2 img {
    width: 100%;
    height: 113%;
    object-fit: contain;
    object-position: center;
}

/* .themePage-website-features {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 4rem 7rem 4rem 7.5rem;
    height: 100%;
} */

.themePage-website-features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 8rem;
    text-align: left;
    /* margin-bottom: 4rem; */
    margin-bottom: 2rem !important;
}

[class^="themePage-website-features-grid-"] h4{
    font-family: Popins-Medium !important;
    font-weight: normal;
  }

.themePage-website-features h1 {
    font-size: 4rem;
    margin-bottom: 4rem;
}

.themePage-website-features p {
    margin-bottom: 2rem;
}

/* .themePage-website-header {
    padding-right: 30%;
    width: 100%;
    text-align: left;
} */

.themes-view-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2rem 0 0 0;
}

.themes-view-button button {
    width: 13% !important;
    padding: 0.5rem 1rem;
    border: none;
}


.themePage-website-support-section {
    padding: 4rem 7rem;
    display: flex;
    align-items: center;
    justify-content: start;
    background-color: rgb(233, 249, 255);
}

.themePage-website-support {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


/* .themePage-website-support-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    vertical-align: middle;
} */

.themePage-website-support-header {

    width: 100%;
    text-align: left;
}

.themePage-website-support-header h1 {
    font-size: 4rem;

    margin-bottom: 2rem;
}

.themePage-website-support-grid-1 {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    padding: 0.5rem;
    border-top: 2px solid black;

}

.themePage-website-support-grid-1 h2 {
    margin: 2rem 0;
    font-family: Popins-Medium !important;
}

.themePage-website-support-grid-1 p {
    margin-bottom: 2rem;
}

.themePage-website-support-grid-1 span {
    font-weight: normal;
    border-bottom: 2px solid black;
    transition: border 0.3s ease;
    font-family: Popins-Semibold !important;
}

.themePage-website-support-grid-1 span:hover {
    cursor: pointer;
    border: none;
}

.themePage-website-success-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 3rem;
    background-color: #0093E9;
    background-image: linear-gradient(0deg, #106df6, #aae5ff );
}

.themePage-website-success {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.themePage-website-success h2 {
    margin: 0;
    font-size: 4.9rem;
    font-weight: normal;
    font-family: Popins-Semibold !important;;
}




@media (min-width: 2550px) {
    .themePage-website-features {
        display: flex;
        align-items: center !important;
        justify-content: center;
        flex-direction: column;
        padding: 4rem 7rem 4rem 7.5rem;
        height: 100%;
    }

    .themePage-website-header {
        padding-right: 30%;
        width: 100%;
        text-align: center !important;
    }

    .themePage-featured-grid-1 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }

    .themePage-featured-header h1 {
        font-size: 4rem !important;
        text-align: left;
        padding-right: 28rem;
        margin: 2rem 0
    }


    .themePage-website-support-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 21rem;
        vertical-align: middle;
    }

    .themePage-website-success h2 {
        margin: 0;
        font-size: 7.6rem;
        font-weight: normal;
        font-family: Popins-Semibold !important;;
        text-transform: uppercase;
    }

    .themePage-website-features h1 {
        font-size: 4rem;
        margin-bottom: 4rem;
        text-align: left;
    }

    .themePage-website-features-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 17rem;
        text-align: left;
        /* margin-bottom: 4rem; */
        margin-bottom: 2rem !important;
    }
}

@media (max-width: 2548px) {
    .themePage-website-features {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        padding: 4rem 7rem 4rem 7.5rem;
        height: 100%;
    }

    .themePage-website-header {
        padding-right: 30%;
        width: 100%;
        text-align: left;
    }

    .themePage-featured-grid-1 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .themePage-featured-header h1 {
        font-size: 4rem;
        text-align: left;
        padding-right: 12rem;
        margin: 0rem 0 2rem 0
    }

    .themePage-website-support-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
        vertical-align: middle;
    }

    .themePage-website-success h2 {
        margin: 0;
        font-size: 4.2rem;
        font-weight: normal;
        text-transform: uppercase;
        font-family: Popins-Medium !important;
    }

}

@media (max-width:1440px) {
    .themePage-website-success h2 {
        margin: 0;
        font-size: 4rem;
        font-weight: normal;
    }
}

@media (max-width:1024px) {
    .themePage-header {
        padding: 4rem;
    }

    .themePage-content-header {
        text-align: left;
        padding: 4rem 4rem 0 4rem;
        background-color: rgb(240, 240, 240);
    }

    .themePage-content-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        padding: 2rem 4rem 4rem 4rem;
        background-color: rgb(240, 240, 240);
    }

    .themePage-featured-grid {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border-radius: 10px;
    }

    .themePage-featured {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: center;
        padding: 4rem 3rem;
    }

    .themePage-featured-grid-1 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        /* padding-left: 6.5rem; */
    }

    .themePage-featured-header {
        width: 100%;
    }
    .themePage-featured-content{
        padding-left: 3rem;
    }

    .themePage-featured-header h1 {
        font-size: 4rem;
        text-align: left;
        padding-right: 0rem;
        margin: 0rem 0 1rem;
    }

    .themePage-featured-grid-2 img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }

    .themePage-website-header {
        padding-right: 0%;
        width: 100%;
        text-align: left;
    }

    .themePage-website-features {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        padding: 4rem;
        height: 100%;
    }

    .themePage-website-features-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 4rem;
        row-gap: 2rem;
        text-align: left;
        /* margin-bottom: 4rem; */
        margin-bottom: 2rem !important;
    }
    .themePage-website-support-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* 2 items per row */
        gap: 2rem;
    }

    .themePage-website-support-grid-1 h2 {
        margin: 2rem 0;
        font-size: 1.3rem;
    }

    .themePage-website-support-grid-1 p {
        margin-bottom: 2rem;
        font-size: 1.1rem;
    }

    .themePage-website-support-grid-1 span {
        border-bottom: 2px solid black;
        transition: border 0.3s ease;
        font-size: 1.1rem;
    }

    .themePage-website-features-grid h4 {
        font-size: 1.3rem;
    }

    .themePage-website-support-section {
        padding: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(233, 249, 255);
    }

    .themePage-website-success h2 {
        margin: 0;
        font-size: 4rem;
        font-weight: normal;
        text-align: center;
    }
    .themes-view-button button {
        width: 20% !important;
        padding: 0.5rem 1rem;
    }
}
@media (max-width:820px) {
    .themePage-header {
        padding: 2rem 2rem;
    }
    .themePage-content-header {
        text-align: left;
        padding: 4rem 2rem 0 2rem;
        background-color: rgb(240, 240, 240);
    }
    .themePage-header h1 {
        font-size: 3rem;
        margin-bottom: 2rem;
    }
    .themePage-content-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        padding: 2rem 2rem 4rem 2rem;
        background-color: rgb(240, 240, 240);
    }
    .themePage-website-support-grid-1 span {
        border-bottom: 2px solid black;
        transition: border 0.3s ease;
        font-size: 1rem;
    }
    .themePage-website-features h1 {
        font-size: 3.7rem;
        margin-bottom: 4rem;
    }
    .themePage-website-features-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 4rem;
        text-align: left;
        /* margin-bottom: 4rem; */
        margin-bottom: 2rem !important;
    }
    .themePage-website-support-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        vertical-align: middle;
    }
    .themePage-website-support-grid-1 h2 {
        margin: 2rem 0;
        font-size: 1.5rem;
    }

    .themePage-website-support-grid-1 p {
        margin-bottom: 2rem;
        font-size: 1rem;
    }
    .themePage-website-success h2 {
        margin: 0;
        font-size: 2.5rem;
        font-weight: normal;
        text-align: center;
    }
    .themePage-featured {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: center;
        padding: 4rem 2rem;
    }
    .themePage-website-features {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        padding: 4rem 2rem;
        height: 100%;
    }
    .themePage-website-support-section {
        padding: 4rem 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(233, 249, 255);
    }
}

@media (max-width:768px) {
    .themePage-header {
        margin-bottom: 2rem;
        padding: 2rem 1.7rem;
        margin-bottom: 0;
    }



    .themePage-header h1 {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .themePage-header p {
        font-weight: normal;
        font-family: Popins-Medium !important;
    }

    .themePage-content-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        padding: 2rem 1.7rem;
    }

    .themePage-content-header {
        text-align: left;
        padding: 2rem 1.7rem;
    }

    .themePage-content-header h3 {
        text-align: left;
        padding: 0rem 0rem;
        font-size: 2rem;
        font-weight: 300;
    }

    .themePage-content-header span {
        text-align: left;
        font-size: 1rem;
    }

    .theme-grid {
        width: 100%;
        height: 100%;
        overflow: hidden;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    }

    .theme-grid img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.2s ease;
    }

    .theme-grid img:hover {
        transform: scale(1.2);
        cursor: pointer;
    }

    .themePage-featured {
        height: 100%;
        width: 100%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .themePage-featured-header h1 {
        margin: 0;
    }

    .themePage-featured-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        background-color: white;
        padding: 2rem 1rem;
        gap: 1rem;
        border-radius: 10px;
    }

    .themePage-featured-grid-1 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-left: 0;
    }

    .themePage-featured-header h1 {
        font-size: 3.5rem;
        text-align: left;
        padding: 0 0 0 0.7rem;
        margin-bottom: 40px;
    }

    .themePage-featured-content ul li {
        margin-bottom: 0;
        margin-bottom: 0.5rem;
        font-size: 1rem;
    }

    .themePage-featured-content {
        width: 100%;
        padding-left: 3rem;
    }

    .themePage-featured-button {
        display: flex;
        justify-content: center !important;
        padding-left: 2rem;
    }

    .themePage-featured-grid-2 {
        width: 100%;
        border-radius: 20px;
        height: 400px;
        position: relative;
    }

    .themePage-featured-grid-2 img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: contain;
        /* margin-bottom: 40px; */
        /* object-position: bottom; */
    }

    .themePage-website-features {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        padding: 2rem 1.7rem;
        height: 100%;
    }

    .themePage-website-features-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        text-align: start;
        margin-bottom: 0 !important;
    }

    .themePage-website-features h1 {
        font-size: 3.9rem;
        margin-bottom: 4rem;
        text-align: start;
    }

    .themePage-website-features p {
        margin-bottom: 2rem;
        font-size: 1rem;
    }

    .themePage-website-header {
        padding-right: 0%;
        width: 100%;
        text-align: left;
    }


    .themePage-website-support-section {
        padding: 2rem 1.7rem;
        display: flex;
        align-items: center;
        justify-content: start;
        height: 100%;
        background-color: rgb(233, 249, 255);
    }

    .themePage-website-support {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }


    .themePage-website-support-grid {
        display: grid;
        grid-template-columns: 1fr;
        vertical-align: middle;
    }

    .themePage-website-support-header {

        width: 100%;
        text-align: left;
    }

    .themePage-website-support-header h1 {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .themePage-website-support-grid-1 {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
        padding: 0.5rem;

    }

    .themePage-website-support-grid-1 h2 {
        font-size: 1.3rem;
        margin-bottom: 2rem;
    }

    .themePage-website-support-grid-1 p {
        font-size: 1rem;
    }

    .themePage-website-support-grid-1 span {
        border-bottom: 2px solid black;
        transition: border 0.3s ease;
        font-size: 1.2rem;
    }

    .themePage-website-support-grid-1 span:hover {
        cursor: pointer;
        border: none;
    }

    .themePage-website-success-section {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 1rem;
        background-color: #0093E9;
        background-image: linear-gradient(0deg, #106df6, #aae5ff );
    }

    .themePage-website-success {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    .themePage-website-success h2 {
        margin: 0;
        font-size: 2.3rem;
        font-weight: normal;
    }
}
@media (max-width:450px) {
    .themePage-header {
        margin-bottom: 2rem;
        padding: 2rem 1.7rem;
        margin-bottom: 0;
    }



    .themePage-header h1 {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .themePage-header p {
        font-weight: normal;
    }

    .themePage-content-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        padding: 2rem 1.7rem;
    }

    .themePage-content-header {
        text-align: left;
        padding: 2rem 1.7rem;
    }

    .themePage-content-header h3 {
        text-align: left;
        padding: 0rem 0rem;
        font-size: 2rem;
        font-weight: 300;
    }

    .themePage-content-header span {
        text-align: left;
        font-size: 1rem;
    }

    .theme-grid {
        width: 100%;
        height: 100%;
        overflow: hidden;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    }

    .theme-grid img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.2s ease;
    }

    .theme-grid img:hover {
        transform: scale(1.2);
        cursor: pointer;
    }

    .themePage-featured {
        height: 100%;
        width: 100%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .themePage-featured-header h1 {
        margin: 0;
    }

    .themePage-featured-grid {
        display: grid;
        grid-template-columns: 1fr;
        background-color: white;
        padding: 2rem 1rem;
        gap: 1rem;
        border-radius: 10px;
    }

    .themePage-featured-grid-1 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-left: 0;
    }

    .themePage-featured-header h1 {
        font-size: 2rem;
        text-align: left;
        padding: 0 0 0 0.7rem;
        margin-bottom: 40px;
    }

    .themePage-featured-content ul li {
        margin-bottom: 0;
        margin-bottom: 0.5rem;
        font-size: 1rem;
    }

    .themePage-featured-content {
        width: 100%;
        padding-left: 2rem;
    }

    .themePage-featured-button {
        display: flex;
        justify-content: center !important;
        padding : 2rem 0 0rem 0
    }
    .themePage-featured-button button{
       
        display: flex;
        justify-content: center;
        align-items: center;
        /* width: 100%; */
        margin-left: 0;
    }

    .themePage-featured-grid-2 {
        width: 100%;
        border-radius: 20px;
        height: 400px;
        position: relative;
    }

    .themePage-featured-grid-2 img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: contain;
        /* margin-bottom: 40px; */
        /* object-position: bottom; */
    }

    .themePage-website-features {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        padding: 2rem 1.7rem;
        height: 100%;
    }

    .themePage-website-features-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        text-align: start;
        margin-bottom: 0 !important;
    }

    .themePage-website-features h1 {
        font-size: 1.9rem;
        margin-bottom: 4rem;
        text-align: start;
    }

    .themePage-website-features p {
        margin-bottom: 2rem;
        font-size: 1rem;
    }

    .themePage-website-header {
        padding-right: 0%;
        width: 100%;
        text-align: left;
    }


    .themePage-website-support-section {
        padding: 2rem 1.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        background-color: rgb(233, 249, 255);
    }

    .themePage-website-support {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }


    .themePage-website-support-grid {
        display: grid;
        grid-template-columns: 1fr;
        vertical-align: middle;
    }

    .themePage-website-support-header {

        width: 100%;
        text-align: left;
    }

    .themePage-website-support-header h1 {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .themePage-website-support-grid-1 {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
        padding: 0.5rem;

    }

    .themePage-website-support-grid-1 h2 {
        font-size: 1.3rem;
        margin-bottom: 2rem;
    }

    .themePage-website-support-grid-1 p {
        font-size: 1rem;
    }

    .themePage-website-support-grid-1 span {
        border-bottom: 2px solid black;
        transition: border 0.3s ease;
        font-size: 1.2rem;
    }

    .themePage-website-support-grid-1 span:hover {
        cursor: pointer;
        border: none;
    }

    .themePage-website-success-section {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 1rem;
        background-color: #0093E9;
        background-image: linear-gradient(0deg, #106df6, #aae5ff );
    }

    .themePage-website-success {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    .themePage-website-success h2 {
        margin: 0;
        font-size: 2rem;
        font-weight: normal;
    }
}

@media (max-width:450px) and (min-width:322px) {
    .themePage-header h1 {
        font-size: calc(1.7rem + (2 - 1.7) * ((100vw - 321px) / (450 - 321)));
    }
    .themePage-content-header h3 {
        font-size: calc(1.7rem + (2 - 1.7) * ((100vw - 321px) / (450 - 321)));
    }
}
@media (max-width:321px) {
    .themePage-header {
        margin-bottom: 2rem;
        padding: 2rem 1.7rem;
        margin-bottom: 0;
    }



    .themePage-header h1 {
        font-size: 1.7rem;
        margin-bottom: 2rem;
    }

    .themePage-header p {
        font-weight: normal;
        font-size: 1.1rem;
    }

    .themePage-content-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        padding: 2rem 1.7rem;
    }

    .themePage-content-header {
        text-align: left;
        padding: 2rem 1.7rem;
    }

    .themePage-content-header h3 {
        text-align: left;
        padding: 0rem 0rem;
        font-size: 1.7rem;
        font-weight: 300;
    }

    .themePage-content-header span {
        text-align: left;
        font-size: 1rem;
    }

    .theme-grid {
        width: 100%;
        height: 100%;
        overflow: hidden;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    }

    .theme-grid img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.2s ease;
    }

    .theme-grid img:hover {
        transform: scale(1.2);
        cursor: pointer;
    }

    .themePage-featured {
        height: 100%;
        width: 100%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .themePage-featured-header h1 {
        margin: 0;
    }

    .themePage-featured-grid {
        display: grid;
        grid-template-columns: 1fr;
        background-color: white;
        padding: 2rem 1rem 0;
        gap: 1rem;
        border-radius: 10px;
    }

    .themePage-featured-grid-1 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-left: 0;
    }

    .themePage-featured-header h1 {
        font-size: 2rem;
        text-align: left;
        padding: 0 0 0 0.7rem;
        margin-bottom: 40px;
    }

    .themePage-featured-content ul li {
        margin-bottom: 0;
        margin-bottom: 0.5rem;
        font-size: 1rem;
    }

    .themePage-featured-content {
        width: 100%;
        padding-left: 2rem;
    }

    .themePage-featured-button {
        display: flex;
        justify-content: center !important;
        padding : 2rem 0 0rem 0
    }
    .themePage-featured-button button{
       
        display: flex;
        justify-content: center;
        align-items: center;
        /* width: 100%; */
        margin-left: 0;
    }

    .themePage-featured-grid-2 {
        width: 100%;
        border-radius: 20px;
        height: 250px;
        position: relative;
    }

    .themePage-featured-grid-2 img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: contain;
        /* margin-bottom: 40px; */
        /* object-position: bottom; */
    }

    .themePage-website-features {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        padding: 1rem 1.7rem 2rem;
        height: 100%;
    }

    .themePage-website-features-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        text-align: start;
        margin-bottom: 0 !important;
    }

    .themePage-website-features h1 {
        font-size: 1.9rem;
        margin-bottom: 4rem;
        text-align: start;
    }

    .themePage-website-features p {
        margin-bottom: 2rem;
        font-size: 1rem;
    }

    .themePage-website-header {
        padding-right: 0%;
        width: 100%;
        text-align: left;
    }


    .themePage-website-support-section {
        padding: 2rem 1.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        background-color: rgb(233, 249, 255);
    }

    .themePage-website-support {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }


    .themePage-website-support-grid {
        display: grid;
        grid-template-columns: 1fr;
        vertical-align: middle;
    }

    .themePage-website-support-header {

        width: 100%;
        text-align: left;
    }

    .themePage-website-support-header h1 {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .themePage-website-support-grid-1 {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
        padding: 0.5rem;

    }

    .themePage-website-support-grid-1 h2 {
        font-size: 1.3rem;
        margin-bottom: 2rem;
    }

    .themePage-website-support-grid-1 p {
        font-size: 1rem;
    }

    .themePage-website-support-grid-1 span {
        border-bottom: 2px solid black;
        transition: border 0.3s ease;
        font-size: 0.9rem;
    }

    .themePage-website-support-grid-1 span:hover {
        cursor: pointer;
        border: none;
    }

    .themePage-website-success-section {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 1rem;
        background-color: #0093E9;
        background-image: linear-gradient(0deg, #106df6, #aae5ff );
    }

    .themePage-website-success {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    .themePage-website-success h2 {
        margin: 0;
        font-size: 2rem;
        font-weight: normal;
    }
}