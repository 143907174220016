@font-face {
    font-family: BAUHS93;
    src: url('../assets/WONO_images/img/fonts/BAUHS93.ttf');
}

@font-face {
    font-family: Bebas-Regular;
    src: url('../assets/WONO_images/img/fonts/BebasNeue-Regular.ttf');
}

@font-face {
    font-family: Popins-Medium;
    src: url('../assets/WONO_images/img/fonts/Poppins-Medium.ttf');
}

@font-face {
    font-family: Popins-Regular;
    src: url('../assets/WONO_images/img/fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: Popins-Black;
    src: url('../assets/WONO_images/img/fonts/Poppins-Black.ttf');
}
.grow-your-business-color h2 {
    text-transform: uppercase;
    font-size: 5.1rem;
}


@media (min-width:2550px) {

    .leadsCtaTextFont {
        font-size: 3.25rem !important;
    }

    .automatedPlatformFontSize {
        font-size: 2.125rem;
    }

    .grow-your-business-color h2 {
        font-size: 9.2rem;
    }
}


@media (max-width: 2548px) {
    .grow-your-business-color h2 {
        font-size: 5.3rem;
    }
}


.body-partners-master {
    background-color: rgb(255, 255, 255);
}


.grow-your-business-color p {
    font-size: 1.25brem;
}

.partners-section>* {
    font-family: Popins-Regular;
}

.customMargin {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #0093E9;
    background-image: linear-gradient(0deg, #106df6, #aae5ff);
    width: 100%;
    text-align: center;
    padding: 5rem;
}

.customMargin_top {
    display: flex;
    align-items: start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 4rem 4.7rem 4rem 6.8rem;
    width: 100%;
}

.customMargin_bottom {
    display: flex;
    align-items: start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 4rem 4.7rem 4rem 6.8rem;
    width: 100%;
}

.testimonial-right-spacing {
    padding-right: 4rem;
}


/* Default for desktop screens */
/* .element {
    
} */

@media (min-width:2550px) {
    .testimonial-right-spacing {
        padding-right: 4rem;
        padding-bottom: 10rem;
        font-size: 3rem;
    }
    .testimonial-contact h4{
        font-size: 3rem;
    }
    .testimonial-contact{
        font-size: 2rem;
    }
}

/* For mobile screens (e.g., max-width 767px) */
@media (max-width:1440px) {
    .grow-your-business-color h2 {
        font-size: 5rem;
    }
}


@media (max-width:1024px) {
    .customMargin_top {
        display: flex;
        align-items: start;
        justify-content: flex-start;
        flex-direction: column;
        padding: 2rem 3rem;
        width: 100%;
    }

    .customMargin_bottom {
        display: flex;
        align-items: start;
        justify-content: flex-start;
        flex-direction: column;
        padding: 2rem 2rem;
        width: 100%;
    }

    .body-partners-master {
        background-color: rgb(255, 255, 255);
        padding-left: 4.5rem;
        /* padding-left: 1.5rem; */
    }

    .grow-your-business-section h2{
        font-size: 3.8rem !important;
    }
}

@media (max-width:820px) {
    .custom-image-biznest-container img {
        width: 100%;
        height: 75%;
        object-fit: cover;
    }

    .body-partners-master {
        background-color: rgb(255, 255, 255);
        /* padding-left: 4.5rem; */
        padding-left: 1.5rem;
    }
    .grow-your-business-color h2 {
        font-size: 4rem;
    }
    .grow-your-business-color p {
        font-size: 1.5rem;
        text-align: start;
    }
}

@media (min-width: 1024px) {


    .custom-justify {
        justify-content: flex-start;
    }

    .testimonial-right-spacing {
        padding-right: 4rem;
    }


}



@media (min-width: 768px) {


    .custom-justify {
        justify-content: flex-start;
    }




}



.get-started-submit-button {
    width: 100%;
    border-radius: 4rem;
    background-color: black;
    color: white;
    /* padding: 0.5rem; */
    /* padding: 20px; */
    padding: 10px 20px;
    border: none;
}

.partner-button-space {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-transform: uppercase;
}

.partner-submit-button {
    justify-content: space-around;
    border-radius: 4rem;
    background-color: black;
    color: white;
    margin: 0;
    padding: 10px 40px;
    border: none;
}

.custom-image-biznest {
    min-height: 400px;
    border-radius: 10px;
}



/* .grow-your-business-color {
    background-color: #0093E9;
    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
    width: 100%;
} */

.container,
.row {
    max-width: 100%;
}

.customWidth {
    width: 60%;
    margin: auto;
}

.last-spacing {
    margin-bottom: 30px;
}

.smaller-font-for-para {
    font-size: 14px !important;
}

.full-width-image {
    width: 100%;
}

.custom-image-biznest-container {
    height: 100%;
    overflow: hidden;
    border-radius: 5px;
}


.custom-image-biznest-container img {
    width: 75%;
    height: 75%;
    object-fit: cover;

}

@media (max-width:1024px) {
    .custom-image-biznest-container {
        margin: 0 0 2rem 0 !important
    }

    .custom-image-biznest {
        min-height: 400px;
        border-radius: 10px;
        object-fit: cover;
        object-position: center;
    }

    .custom-image-biznest-container img {
        width: 100%;
        height: 75%;
        object-fit: cover;

    }
    .grow-your-business-section h2{
        font-size: 3.8rem !important;
    }


}

@media  (max-width: 821px) {
    .customMargin_top {
        padding: 2rem 1rem;
    }

    .customMargin_bottom {
        padding: 2rem 1rem;
    }
    .testimonial-right-spacing {
        padding-right: 0rem;
        padding-bottom: 8rem;
    }
    .grow-your-business-section h2 {
        font-size: 3.1rem !important;
    }

}


@media (max-width: 768px) {
    .customMargin {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .custom-justify {
        justify-content: center;
    }

    .customMargin_top {
        display: flex;
        align-items: start;
        justify-content: flex-start;
        flex-direction: column;
        padding: 2rem 1rem 2rem 1rem;
        width: 100%;
    }

    .customMargin_bottom {
        display: flex;
        align-items: start;
        justify-content: flex-start;
        flex-direction: column;
        padding: 2rem 1rem;
        width: 100%;
    }

    .body-partners-master {
        background-color: rgb(255, 255, 255);
        padding-left: 2rem;
    }

    .testimonial-right-spacing {
        padding-right: 0rem;
        padding-bottom: 4rem;
    }

    .custom-image-biznest {
        width: 100%;
        /* Image will take up the full width of its container */
        /* height: auto; */
        min-height: 400px;
        /* Maintains the image's aspect ratio */
        border-radius: 10px;
    }

    .custom-image-biznest-container img {
        width: 100%;
        height: 75%;
        object-fit: cover;

    }

    .grow-your-business-section h2 {
        font-size: 2.9rem !important;
    }
    

}

@media (max-width:425px) {
    .custom-image-biznest {
        width: 100%;
        /* Image will take up the full width of its container */
        /* height: auto; */
        min-height: 400px;
        /* Maintains the image's aspect ratio */
        border-radius: 10px;
    }

    .custom-image-biznest-container img {
        width: 100%;
        height: 75%;
        object-fit: cover;

    }

    .testimonial-right-spacing {
        padding-right: 0rem;
        padding-bottom: 2rem;
    }
}

@media (max-width: 321px) {
    .customMargin {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .custom-justify {
        justify-content: center;
    }

    .customMargin_top {
        display: flex;
        align-items: start;
        justify-content: flex-start;
        flex-direction: column;
        padding: 2rem 1rem 2rem 1rem;
        width: 100%;
    }

    .customMargin_bottom {
        display: flex;
        align-items: start;
        justify-content: flex-start;
        flex-direction: column;
        padding: 2rem 1rem;
        width: 100%;
    }

    .body-partners-master {
        background-color: rgb(255, 255, 255);
        padding-left: 2rem;
    }

    .testimonial-right-spacing {
        padding-right: 0rem;
        padding-bottom: 4rem;
    }

    .custom-image-biznest {
        width: 100%;
        /* Image will take up the full width of its container */
        /* height: auto; */
        min-height: 400px;
        /* Maintains the image's aspect ratio */
        border-radius: 10px;
    }

    .custom-image-biznest-container img {
        width: 100%;
        height: 75%;
        object-fit: cover;

    }

    .grow-your-business-section h2 {
        font-size: 2rem !important;
    }

    .grow-your-business-color p {
        font-size: 1rem !important;
        text-align: start;
    }
    

}