@font-face {
    font-family: Popins-Semibold;
    src: url('../assets/WONO_images/img/fonts/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: Popins-Regular;
    src: url('../assets/WONO_images/img/fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: Caveat;
    src: url('../assets/WONO_images/img/fonts/Caveat.ttf');
}

@font-face {
    font-family: ComicSansMS-bold;
    src: url('../assets/WONO_images/img/fonts/ComicSansMS.ttf');
}
@font-face {
    font-family: ComicSansMS-bold;
    src: url('../assets/WONO_images/img/fonts/comic-bold.ttf');
}


.services {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

/* .services * {
    font-family: Popins-Regular;
} */

.services:not(:last-child) {
    padding: 0 7.5rem;
}

.SaaS-main-heading {
    text-align: center;
    padding: 4rem 0 4rem 0;

}

.services-animation-container {
    position: relative;
    font-family: ComicSansMS-bold;
    width: 100%;
}

.services-animation-container img {
    position: absolute;
    top: 99%;
    left: -8%;
    width: 116%;
}


/* .services-blue-underline {
    position: absolute;
    top: 32px;
    left: 6px;
    z-index: -2;
} */




.SaaS-main-heading h1 {
    font-size: 3rem;
    padding: 0 0 2rem 0;
    font-weight: normal;
    font-family: ComicSansMS-bold;
}

.SaaS-main-heading h4 {
    padding-bottom: 2rem;
    font-family: Popins-Regular;
}

.SaaS-main-tagline {
    font-size: 1.3rem;
}

.plus-sign {
    color: #0AA9EF;
    font-weight: 900;
}


.services-header-button-space {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 2.5rem;
}

.services-footer-button-space {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.SaaS-featured-master h1 {
    padding-top: 4rem;
    font-weight: normal;
    margin: 0;
    font-family: ComicSansMS-bold;
}

.SaaS-featured-master>h1 span {
    font-family: ComicSansMS-bold;
}

.SaaS-featured-heading-container {
    position: relative;
    font-family: ComicSansMS-bold;
    width: 100%;

}

.SaaS-featured-heading-container img {
    position: absolute;
    top: 85%;
    left: 2%;
    width: 41%;
}

.SaaS-features-heading {
    position: relative;
    width: 100%;
}

.SaaS-features-heading img {
    position: absolute;
    top: 88%;
    left: 0%;
    width: 46%;
}

/* .SaaS-blue-underline {
    position: absolute;
    width: 100%;
    height: 64px;
    top: 3px;
    left: -142px;
} */
.SaaS-features-frontend {
    position: relative;
    width: 100%;
}

.SaaS-features-frontend img {
    position: absolute;
    width: 100%;
    height: 55%;
    top: 78%;
    left: -1%;
}

.SaaS-features-finance {
    position: relative;
    width: 100%;
}

.SaaS-features-finance img {
    position: absolute;
    top: 79%;
    left: -1%;
    width: 100%;
    height: 69%;
}

.SaaS-features-sales {
    position: relative;
    width: 100%;
}

.SaaS-features-sales img {
    position: absolute;
    top: 79%;
    left: -1%;
    width: 100%;
    height: 69%;
}

.SaaS-features-hr {
    position: relative;
    width: 100%;
}

.SaaS-features-hr img {
    position: absolute;
    top: 79%;
    left: -2%;
    width: 100%;
    height: 56%;
}

.SaaS-features-cms {
    position: relative;
    width: 100%;
}

.SaaS-features-cms img {
    position: absolute;
    top: 79%;
    left: -2%;
    width: 100%;
    height: 56%;
}

.SaaS-features-reports {
    position: relative;
    width: 100%;
}

.SaaS-features-reports img {
    position: absolute;
    top: 79%;
    left: -2%;
    width: 100%;
    height: 56%;
}


.SaaS-featured-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 4rem 0;
    column-gap: 2rem;
}

.SaaS-featured-grid-left {
    font-size: 1.5rem;
    font-family: Popins-Regular;
}

.SaaS-featured-grid-right {
    width: 100%;
    overflow: hidden;
}

.SaaS-featured-grid-right img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.SaaS-grid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.SaaS-grid-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.SaaS-grid-mobile{
    display: none;
    flex-direction: column;
}
.SaaS-grid-accordion{
    display: none;
}

.SaaS-grid-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem 0 0 0;
    width: 100%;

}

.SaaS-grid-heading h2 {
    font-weight: normal;
    font-size: 4rem;
    font-family: ComicSansMS-bold;
    position: relative;
    -webkit-font-smoothing: antialiased;
}

.SaaS-grid-heading>h2 span {
    font-family: ComicSansMS-bold;
}

.SaaS-grid-underline-container {
    position: relative;
    font-size: 4rem !important;
    font-family: ComicSansMS-bold;
    width: 100%;
}

.SaaS-grid-underline-container img {
    position: absolute;
    top: 75%;
    left: 0%;
    width: 99%;
    height: 22%;
}

.SaaS-grid-underline-container * {
    font-family: ComicSansMS-bold;
}

/* .SaaS-grid-blue-underline {
    position: absolute;
    width: 100px;
    height: 108px;
    top: 0px;
    left: 0px;
} */

/* .SaaS-grid-blue-underline path {
    fill: #0093E9;
    stroke: #0093E9;
    stroke-width: 0;
    height: 1px;
} */



.SaaS-grid-heading span {
    font-size: 1.5rem;
    padding: 1rem 0 1rem 0;
}



.businesses {
    width: 100%;
    padding: 2rem 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.businesses>div>h1 {
    text-align: start;
    font-size: 2rem;
    font-weight: normal;
    text-align: start;
    font-family: Popins-Semibold !important;
    color: black;
    text-transform: uppercase;

}

.businesses>div>hr {
    margin: 0;
    border: 4px solid #0875e2;
    opacity: 1;
    padding: 0;
    width: 90%;
}

/* .business-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    margin: 4rem 0 4rem 0;
    padding: 0;
    column-gap: 10rem;
    row-gap: 3rem;
} */

.business-service-card {
    display: flex;
    align-items: center; /* Vertically center the content */
    padding: 1rem;
    background-color: #FFF;
    column-gap: 1.5rem; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds a soft drop shadow */
}


.business-service-card-image {
    flex: 0 0 15%; /* Fixed width for the image section, 27% of the card width */
}

.business-service-card-content {
    flex: 1; /* The content section takes up the remaining space */
}

.business-service-card-content h4 {
    font-size: 1rem;
    margin: 0;
    font-family: Popins-Semibold !important;
    font-weight: normal;
    text-align: start;
    line-height: 182%;
}

.business-service-card-content span {
    font-size: 0.8rem;
    margin: 0;
    width: 100%;
    font-family: Popins-Regular;
}

.business-service-card-image {
    /* background-color: red; */
    padding: 0.4rem 0.6rem;
    width: 60px;
    border-radius: 5px;
}

.business-service-card img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.business-service>span {
    font-family: Popins-Regular;
    font-weight: 700;
    text-align: center;
}

.business-service {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SaaS-last-banner-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 0;
    background-color: #0093E9;
    background-image: linear-gradient(0deg, #106df6, #aae5ff);
    width: calc(100% + 15rem);
    margin: 0 -7.5rem;
    text-align: center;
    text-transform: uppercase;
}

/* .SaaS-last-banner h2 {
    font-family: Popins-Semibold;
    font-size: 5.2rem;
} */


.business-service>img {
    width: 50px;
    margin-bottom: 0.8rem;
}

.services-header-button-space {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 2rem;
}

@media (min-width: 2550px) {
    .business-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin: 2rem 0;
        padding: 0;
        column-gap: 5rem;
    }
    .business-grid > div {
        flex: 1 1 calc(33.33% - 4rem); /* Each item takes up 33.33% of the width with spacing */
        margin-bottom: 2rem; /* To add space between rows */
    }
    .business-service-card-image {
        flex: 0 0 11%; /* Fixed width for the image section, 27% of the card width */
    }

    .SaaS-last-banner h2 {
        font-family: Popins-Semibold;
        font-size: 8rem;
    }
}

@media (max-width: 2548px) {
    .business-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin: 2rem 0;
        padding: 0;
        column-gap: 2rem;
    }
    .business-grid > div {
        flex: 1 1 calc(33.33% - 4rem); /* Each item takes up 33.33% of the width with spacing */
        margin-bottom: 2rem; /* To add space between rows */
    }

    .SaaS-last-banner h2 {
        font-family: Popins-Semibold;
        font-size: 4.55rem;
    }

    .services-footer-button-space {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 1.5rem 0 0 0;
    }
}

@media (max-width:1440px) {
    .SaaS-last-banner h2 {
        font-family: Popins-Semibold;
        font-size: 4.155rem;
    }
    .SaaS-grid-heading h2 {
        font-weight: normal;
        font-size: 3.8rem;
        font-family: ComicSansMS-bold;
        position: relative;
        -webkit-font-smoothing: antialiased;
    }

}

@media (max-width: 1024px) {
    .services {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
    }


    .services:not(:last-child) {
        padding: 0 4rem;
    }

    .SaaS-grid-underline-container img {
        position: absolute;
        top: 75%;
        left: 0%;
        width: 99%;
        height: 22%;
        display: none;
    }

    .SaaS-features-cms img {
        position: absolute;
        top: 79%;
        left: -2%;
        width: 100%;
        height: 56%;
    }

    .business-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        margin: 4rem 0 4rem 0;
        padding: 0;
        column-gap: 8rem;
        row-gap: 3rem;
    }

    .business-service-card {
        display: grid;
        grid-template-columns: 18% 1fr;
        /* background-color: rgb(247, 247, 247); */
        background-color: #FFF;
        padding: 1rem;
    }

    .business-service-card-content span {
        font-size: 0.8rem;
        margin: 0;
        width: 100%;
        line-height: 100%;
    }

    .SaaS-last-banner-section {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0093E9;
        background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
        width: calc(100% + 8rem);
        margin: 0px -4rem;
        text-align: center;
    }

    .services-footer-button-space {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 1.5rem 0 0 0;
    }
    .SaaS-last-banner-section {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 0;
        background-color: #0093E9;
        background-image: linear-gradient(0deg, #106df6, #aae5ff);
        width: calc(100% + 11.5rem);
        margin: 0 -7.5rem;
        text-align: center;
        text-transform: uppercase;
    }
}

@media (max-width: 820px) {
    .services {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .services:not(:last-child) {
        padding: 0 1.7rem;
    }

    .SaaS-main-heading {
        text-align: start;
        padding: 4rem 0 2rem 0;

    }

    .SaaS-grid-underline-container img {
        position: absolute;
        top: 75%;
        left: 0%;
        width: 99%;
        height: 22%;
        display: none;
    }

    .SaaS-features-cms img {
        position: absolute;
        top: 79%;
        left: -2%;
        width: 100%;
        height: 56%;
    }

    .services-animation-container {
        position: relative;
        font-family: ComicSansMS-bold;
    }

    .services-blue-underline {
        position: absolute;
        top: 5px;
        left: -6px;
        z-index: -2;
    }


    .SaaS-main-heading h1 {
        font-size: 2rem;
        padding: 0 0 2rem 0;
        line-height: 130%;
    }

    .SaaS-main-heading h4 {
        padding-bottom: 2rem;
    }

    .services-header-button-space {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 2rem;
    }

    .services-footer-button-space {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 1.5rem 0 0 0;
    }

    .SaaS-featured-master h1 {
        padding-top: 2rem;
    }

    .SaaS-featured-heading-container {
        position: relative;

    }

    .SaaS-blue-underline {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -98px;

    }

    .SaaS-blue-underline-frontend {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -98px;

    }

    .SaaS-featured-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        padding: 4rem 0;
    }

    .SaaS-featured-grid-right {
        width: 100%;
        overflow: hidden;
    }

    .SaaS-featured-grid-right img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .SaaS-grid {
        padding: 0;
    }

    .SaaS-grid-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .SaaS-grid-heading {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        padding: 2rem 0 0 0;
        width: 100%;
    }

    .SaaS-grid-heading h2 {
        font-size: 2rem;
    }

    .SaaS-grid-underline-container {
        position: relative;
        font-size: 2rem !important;
        font-family: ComicSansMS-bold;
    }

    .SaaS-grid-blue-underline {
        display: none;
        position: absolute;
        width: 100%;
        height: 73px;
        top: -7px;
        left: -112px;
    }

    .SaaS-grid-heading span {
        font-size: 1.5rem;
    }

    .business-service-card {
        display: grid;
        grid-template-columns: 18% 1fr;
        column-gap: 0.5rem;
    }

    .business-service-card-content h4 {
        font-size: 1rem;
        margin: 0;
        font-weight: normal;
    }

    .business-service-card-content span {
        font-size: 0.8rem;
        margin: 0;
        width: 100%;
    }

    .business-service-card-image {
        width: 3.5rem;
    }

    .business-service-card img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }


    .businesses {
        width: 100%;
        padding: 2rem 0 0 0;
    }

    .businesses>div>h1 {
        text-align: start;
        font-size: 1.7rem;
        font-weight: 700;
        font-family: Popins-SemiBold;
        color: black;
        padding: 0;
    }

    .businesses>div>hr {
        margin: 0;
        border: 4px solid #0875e2;
        opacity: 1;
        padding: 0;
        width: 90%;
    }

    .business-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        margin: 4rem 0 4rem 0;
        padding: 0;
        column-gap: 2rem;
        row-gap: 1rem;
    }

    .business-service>span {
        font-family: Popins-Regular;
        font-weight: 700;
        text-align: center;
        font-size: 0.7rem;
    }

    .business-service {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .SaaS-last-banner-section {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 3rem;
        background-color: #0093E9;
        background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
        width: calc(100% + 3rem);
        margin: 0 -2rem;
        text-align: center;
    }

    .SaaS-last-banner h2 {
        font-family: Popins-Semibold;
        font-size: 2.4rem;
    }


    .business-service>img {
        width: 50px;
        margin-bottom: 0.8rem;
    }
}

@media (max-width: 768px) {
    .services {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .services:not(:last-child) {
        padding: 0 1.9rem;
    }

    .SaaS-main-heading {
        text-align: start;
        padding: 0rem 0 2rem 0;

    }

    .services-animation-container {
        position: relative;
        font-family: ComicSansMS-bold;
    }


    .SaaS-grid-underline-container img {
        position: absolute;
        top: 75%;
        left: 0%;
        width: 99%;
        height: 22%;
        display: none;
    }

    .SaaS-features-cms img {
        position: absolute;
        top: 79%;
        left: -2%;
        width: 100%;
        height: 56%;
    }


    .services-blue-underline {
        position: absolute;
        top: 5px;
        left: -6px;
        z-index: -2;
    }


    .SaaS-main-heading h1 {
        font-size: 2rem;
        padding: 2rem 0 2rem 0;
        line-height: 130%;
    }

    .SaaS-main-heading h4 {
        padding-bottom: 2rem;
    }

    .services-header-button-space {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 2rem;
    }

    .services-footer-button-space {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 1.5rem 0 0 0;
    }

    .SaaS-featured-master h1 {
        padding-top: 2rem;
    }

    .SaaS-featured-heading-container {
        position: relative;

    }

    .SaaS-blue-underline {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -98px;

    }

    .SaaS-blue-underline-frontend {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -40px;

    }

    .SaaS-blue-underline-finance-accounting {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -120px;

    }

    .SaaS-blue-underline-customer-management-services {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 30px;
        left: -120px;


    }

    .SaaS-blue-underline-hrsupport {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -40px;

    }

    .SaaS-blue-underline-reports-analytics {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -110px;

    }

    .SaaS-blue-underline-sales-marketing {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -100px;

    }


    .SaaS-featured-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        padding: 4rem 0;
    }

    .SaaS-featured-grid-left {
        font-size: 1.353rem;
    }

    .SaaS-featured-grid-right {
        width: 100%;
        overflow: hidden;
    }

    .SaaS-featured-grid-right img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .SaaS-grid {
        padding: 0;
    }

    .SaaS-grid-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .SaaS-grid-heading {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        padding: 2rem 0 0 0;
        width: 100%;
    }

    .SaaS-grid-heading h2 {
        font-size: 2rem;
    }

    .SaaS-grid-underline-container {
        position: relative;
        font-size: 2rem !important;
        font-family: ComicSansMS-bold;
    }

    .SaaS-grid-blue-underline {
        display: none;
        position: absolute;
        width: 100%;
        height: 73px;
        top: -7px;
        left: -112px;
    }

    .SaaS-grid-heading span {
        font-size: 1.353rem;
        padding-bottom: 0;
    }

    .business-service-card {
        display: grid;
        grid-template-columns: 10% 1fr;
        column-gap: 1rem;
    }

    .business-service-card-content h4 {
        font-size: 1rem;
        margin: 0;
        font-weight: normal;
        line-height: 184%;
    }

    .business-service-card-content span {
        font-size: 0.8rem;
        margin: 0;
        width: 100%;
    }

    .business-service-card-image {
        width: 30px;
        padding: 0;
    }

    .business-service-card img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }


    .businesses {
        width: 100%;
        padding: 2rem 0 0 0;
    }

    .businesses>div>h1 {
        text-align: start;
        font-size: 1.4rem;
        font-weight: 700;
        font-family: Popins-SemiBold;
        color: black;
        padding: 0;
    }

    .businesses>div>hr {
        margin: 0;
        border: 4px solid #0875e2;
        opacity: 1;
        padding: 0;
        width: 90%;
    }

    .business-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        margin: 2rem 0 2rem 0;
        padding: 0;
        column-gap: 2rem;
        row-gap: 1rem;
    }

    .business-service>span {
        font-family: Popins-Regular;
        font-weight: 700;
        text-align: center;
        font-size: 0.7rem;
    }

    .business-service {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .SaaS-last-banner-section {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        background-color: #0093E9;
        background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
        width: calc(100% + 3.4rem);
        margin: 0 -2rem;
        text-align: center;
    }

    .SaaS-last-banner h2 {
        font-family: Popins-Semibold;
        font-size: 2.4rem;
    }


    .business-service>img {
        width: 50px;
        margin-bottom: 0.8rem;
    }
}
@media (max-width: 450px) {
    .services {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .services:not(:last-child) {
        padding: 0 1.9rem;
    }

    .SaaS-main-heading {
        text-align: start;
        padding: 0rem 0 2rem 0;

    }

    .services-animation-container {
        position: relative;
        font-family: ComicSansMS-bold;
    }


    .SaaS-grid-underline-container img {
        position: absolute;
        top: 75%;
        left: 0%;
        width: 99%;
        height: 22%;
        display: none;
    }

    .SaaS-features-cms img {
        position: absolute;
        top: 79%;
        left: -2%;
        width: 100%;
        height: 56%;
    }


    .services-blue-underline {
        position: absolute;
        top: 5px;
        left: -6px;
        z-index: -2;
    }


    .SaaS-main-heading h1 {
        font-size: 2rem;
        padding: 2rem 0 2rem 0;
        line-height: 130%;
    }

    .SaaS-main-heading h4 {
        padding-bottom: 2rem;
    }

    .services-header-button-space {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 2rem;
    }

    .services-footer-button-space {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 1.5rem 0 0 0;
    }

    .SaaS-featured-master h1 {
        padding-top: 2rem;
    }

    .SaaS-featured-heading-container {
        position: relative;

    }

    .SaaS-blue-underline {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -98px;

    }

    .SaaS-blue-underline-frontend {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -40px;

    }

    .SaaS-blue-underline-finance-accounting {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -120px;

    }

    .SaaS-blue-underline-customer-management-services {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 30px;
        left: -120px;


    }

    .SaaS-blue-underline-hrsupport {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -40px;

    }

    .SaaS-blue-underline-reports-analytics {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -110px;

    }

    .SaaS-blue-underline-sales-marketing {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -100px;

    }


    .SaaS-featured-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        padding: 4rem 0;
    }

    .SaaS-featured-grid-left {
        font-size: 1.353rem;
    }

    .SaaS-featured-grid-right {
        width: 100%;
        overflow: hidden;
    }

    .SaaS-featured-grid-right img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .SaaS-grid {
        padding: 0;
    }

    .SaaS-grid-content {
        display: none;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .SaaS-grid-mobile{
        display: flex;
        flex-direction: column;
    }
    .SaaS-grid-accordion{
        display: block;
        width: 100%;
        padding: 2rem 0;
    }
    .SaaS-accordion-item{
        border: none;
    }
    .SaaS-accordion-body{
        padding: 0 !important;
    }

    .SaaS-grid-heading {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        padding: 2rem 0 0 0;
        width: 100%;
    }

    .SaaS-grid-heading h2 {
        font-size: 2rem;
    }

    .SaaS-grid-underline-container {
        position: relative;
        font-size: 2rem !important;
        font-family: ComicSansMS-bold;
    }

    .SaaS-grid-blue-underline {
        display: none;
        position: absolute;
        width: 100%;
        height: 73px;
        top: -7px;
        left: -112px;
    }

    .SaaS-grid-heading span {
        font-size: 1.353rem;
        padding-bottom: 0;
    }

    .business-service-card {
        display: grid;
        grid-template-columns: 10% 1fr;
        column-gap: 1rem;
        border-bottom: 1px solid rgb(136, 136, 136);
        box-shadow: none;
    }

    .business-service-card-content h4 {
        font-size: 1rem;
        margin: 0;
        font-weight: normal;
        line-height: 184%;
    }



    .business-grid-mobile{

    }

    .business-grid > div {
        flex: 1 1 calc(33.33% - 4rem); /* Each item takes up 33.33% of the width with spacing */
        margin-bottom: 0rem; /* To add space between rows */
    }

    .business-service-card-content span {
        font-size: 0.8rem;
        margin: 0;
        width: 100%;
    }

    .business-service-card-image {
        width: 30px;
        padding: 0;
    }

    .business-service-card img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }


    .businesses {
        width: 100%;
        padding: 2rem 0 0 0;
    }

    .businesses>div>h1 {
        text-align: start;
        font-size: 1.08rem;
        font-weight: 700;
        font-family: Popins-SemiBold;
        color: black;
        padding: 0;
    }

    .businesses>div>hr {
        margin: 0;
        border: 4px solid #0875e2;
        opacity: 1;
        padding: 0;
        width: 90%;
    }

    .business-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        margin: 2rem 0 2rem 0;
        padding: 0;
        /* column-gap: 2rem; */
        row-gap: 0rem;
    }

    .business-service>span {
        font-family: Popins-Regular;
        font-weight: 700;
        text-align: center;
        font-size: 0.7rem;
    }

    .business-service {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .SaaS-last-banner-section {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        background-color: #0093E9;
        background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
        width: calc(100% + 3.4rem);
        margin: 0 -2rem;
        text-align: center;
    }

    .SaaS-last-banner h2 {
        font-family: Popins-Semibold;
        font-size: 2rem;
    }


    .business-service>img {
        width: 50px;
        margin-bottom: 0.8rem;
    }
}

@media (max-width: 450px) and (min-width: 321px) {
    .business-service-card-content span {
        font-size: calc(0.8rem + (1 - 0.8) * ((100vw - 321px) / (450 - 321) * 1));
    }
}

@media (max-width: 321px) {
    .services {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .services:not(:last-child) {
        padding: 0 1.9rem;
    }

    .SaaS-main-heading {
        text-align: start;
        padding: 0rem 0 2rem 0;

    }

    .services-animation-container {
        position: relative;
        font-family: ComicSansMS-bold;
    }


    .SaaS-grid-underline-container img {
        position: absolute;
        top: 75%;
        left: 0%;
        width: 99%;
        height: 22%;
        display: none;
    }

    .SaaS-features-cms img {
        position: absolute;
        top: 79%;
        left: -2%;
        width: 100%;
        height: 56%;
    }


    .services-blue-underline {
        position: absolute;
        top: 5px;
        left: -6px;
        z-index: -2;
    }


    .SaaS-main-heading h1 {
        font-size: 2rem;
        padding: 2rem 0 2rem 0;
        line-height: 130%;
    }

    .SaaS-main-heading h4 {
        padding-bottom: 2rem;
    }

    .services-header-button-space {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 2rem;
    }

    .services-footer-button-space {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 1.5rem 0 0 0;
    }

    .SaaS-featured-master h1 {
        padding-top: 2rem;
    }

    .SaaS-featured-heading-container {
        position: relative;

    }

    .SaaS-blue-underline {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -98px;

    }

    .SaaS-blue-underline-frontend {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -40px;

    }

    .SaaS-blue-underline-finance-accounting {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -120px;

    }

    .SaaS-blue-underline-customer-management-services {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 30px;
        left: -120px;


    }

    .SaaS-blue-underline-hrsupport {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -40px;

    }

    .SaaS-blue-underline-reports-analytics {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -110px;

    }

    .SaaS-blue-underline-sales-marketing {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 2px;
        left: -100px;

    }


    .SaaS-featured-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        padding: 4rem 0;
    }

    .SaaS-featured-grid-left {
        font-size: 1.353rem;
    }

    .SaaS-featured-grid-right {
        width: 100%;
        overflow: hidden;
    }

    .SaaS-featured-grid-right img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .SaaS-grid {
        padding: 0;
    }

    .SaaS-grid-content {
        display: none;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .SaaS-grid-mobile{
        display: flex;
        flex-direction: column;
    }
    .SaaS-grid-accordion{
        display: block;
        width: 100%;
        padding: 2rem 0;
    }
    .SaaS-accordion-item{
        border: none;
    }
    .SaaS-accordion-body{
        padding: 0 !important;
    }

    .SaaS-grid-heading {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        padding: 2rem 0 0 0;
        width: 100%;
    }

    .SaaS-grid-heading h2 {
        font-size: 2rem;
    }

    .SaaS-grid-underline-container {
        position: relative;
        font-size: 2rem !important;
        font-family: ComicSansMS-bold;
    }

    .SaaS-grid-blue-underline {
        display: none;
        position: absolute;
        width: 100%;
        height: 73px;
        top: -7px;
        left: -112px;
    }

    .SaaS-grid-heading span {
        font-size: 1.353rem;
        padding-bottom: 0;
    }

    .business-service-card {
        display: grid;
        grid-template-columns: 10% 1fr;
        column-gap: 1rem;
        border-bottom: 1px solid rgb(136, 136, 136);
        box-shadow: none;
    }

    .business-service-card-content h4 {
        font-size: 0.8rem;
        margin: 0;
        font-weight: normal;
        line-height: 184%;
    }

    .business-service-card-content span {
        font-size: 0.6rem;
        margin: 0;
        width: 100%;
    }

    .business-service-card-image {
        width: 27px;
        padding: 0;
    }

    .business-service-card img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }


    .businesses {
        width: 100%;
        padding: 2rem 0 0 0;
    }

    .businesses>div>h1 {
        text-align: start;
        font-size: 1rem;
        font-weight: 700;
        font-family: Popins-SemiBold;
        color: black;
        padding: 0;
        white-space: nowrap;
    }

    .businesses>div>hr {
        margin: 0;
        border: 4px solid #0875e2;
        opacity: 1;
        padding: 0;
        width: 90%;
    }

    .business-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        margin: 2rem 0 2rem 0;
        padding: 0;
        column-gap: 2rem;
        row-gap: 0rem;
    }

    .business-service>span {
        font-family: Popins-Regular;
        font-weight: 700;
        text-align: center;
        font-size: 0.7rem;
    }

    .business-service {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .SaaS-last-banner-section {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        background-color: #0093E9;
        background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
        width: calc(100% + 3.4rem);
        margin: 0 -2rem;
        text-align: center;
    }

    .SaaS-last-banner h2 {
        font-family: Popins-Semibold;
        font-size: 2rem;
    }


    .business-service>img {
        width: 50px;
        margin-bottom: 0.8rem;
    }
}