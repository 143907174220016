@font-face {
    font-family: Popins-Regular;
    src: url('../assets/WONO_images/img/fonts/Poppins-Regular.ttf');
  }

section{
    padding: 40px 0;
    overflow: hidden;
    font-family: Popins-Regular;
}

 .contact-form *{
    font-family: Popins-Regular;
 }

.contact .contact-form{
    width: 100%;
    margin-top: 55px;
}
.contact .contact-form button[type=submit]{
    width: 200px;
}


@media (max-width:768px) {
    .contact .contact-form button[type=submit] {
        padding: 10px;
    }
    .contact .contact-form {
        padding: 0;
    }
    section{
        padding: 0;
        overflow: hidden;
    }
    
}
