* {
    margin: 0;
    padding: 0;
}


@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
      * {
        -webkit-font-smoothing: antialiased;
        font-weight: normal;
        /* -webkit-text-stroke-width: 0!important; */
      }
    }
  }

  @media not all and (min-resolution:.001dpcm){ 
    @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
    * { 
        -webkit-font-smoothing: antialiased; 
        font-weight: normal;
        /* -webkit-text-stroke-width: 0!important; */
    }
    }}

  

@media screen and (min-width: 1365px) and (max-width: 1367px) {
    * {
        display: none !important;
    }
}

@media screen and (min-width: 1178px) and (max-width: 1182px) {
    * {
        display: none !important;
    }
}

.master-container {
    background-color: white;
    height: 100%
}

.placeholder {
    display: inline
}

/* .date-picker>.placeholder::after {
    content: "Date Of Birth";
    pointer-events: none;
    position: absolute;
    inset: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: inherit;
    color: #222;
    padding: 0 10px;
} */

.date-picker {
    position: relative;
}

/* .date-picker>.placeholder::before {
    content: "Date Of Birth";
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 10px;
    
    transform: translateY(-50%);
    color: black;
    font-weight: bold;
    
    padding: 0 5px;
    background: white;
   
    z-index: 1;
} */
.placeholder {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: black;
    font-weight: bold;
    background: white;
    z-index: 1;
    pointer-events: none;
    /* Prevent interaction with the placeholder */
}

.date-picker input {
    position: relative;
    z-index: 2;
    background: transparent;

    color: transparent;

}

.date-picker input:focus {
    color: #000;

}

.date-picker input:active {
    color: #000;

}


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@font-face {
    font-family: Caveat;
    src: url('../assets/WONO_images/img/fonts/Caveat.ttf');
}

@font-face {
    font-family: BAUHS93;
    src: url('../assets/WONO_images/img/fonts/BAUHS93.ttf');
}

@font-face {
    font-family: Bebas-Regular;
    src: url('../assets/WONO_images/img/fonts/BebasNeue-Regular.ttf');
}

@font-face {
    font-family: Popins-Medium;
    src: url('../assets/WONO_images/img/fonts/Poppins-Medium.ttf');
}

@font-face {
    font-family: Popins-Regular;
    src: url('../assets/WONO_images/img/fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: Popins-Black;
    src: url('../assets/WONO_images/img/fonts/Poppins-Black.ttf');
}

@font-face {
    font-family: Mistral;
    src: url('../assets/WONO_images/img/fonts/MISTRAL.TTF');
}

@font-face {
    font-family: Telma-Variable;
    src: url('../assets/WONO_images/img/fonts/Telma-Variable.ttf');
}


.home-page-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 4rem 7.3rem;
    background-color: #f7feec;
}

.background-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    position: absolute;
    top: -40px;
    left: 0;
    z-index: -1;
    display: none;
}

.black-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* background: linear-gradient(to bottom, #ffffff00 56%, #ffffff00 56%, #0a0a0ac9 79%, #000000 100%); */
    /* Semi-transparent black */
    z-index: 0;
}

.first-section-grid-item-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
}

.first-section-grid-item-2 {
    /* display: grid;
    grid-template-columns: 1fr 30%; */
    position: relative;
    z-index: 1;
    width: 100%;
    font-family: Popins-Regular;
    color: white;
    padding: 1rem 0rem 2.5rem 0.3rem;
    align-items: center;
    text-align: center;
    color: black;
}

.home-section-buttons {
    display: flex;
    align-items: start;
    justify-content: center;
    /* padding-left: 4.7rem; */
    width: 100%;
}

.home-section-register-button-space {
    width: 100%;
}


.home-section-register-button {
    width: 100%;
    margin: 0;
    background-color: rgb(13, 110, 253);
    /* padding: 0.5rem 2rem 0.5rem 2rem; */
    padding: 1rem 1.5rem;
    color: white;
    border-radius: 4rem;
    border: none;
    font-family: Popins-Regular;
    cursor: pointer;
    font-size: 16px;
}

.first-section-grid-item-2 * {
    font-family: Popins-Regular;
}

.first-section-grid-item-1 {
    flex-direction: column;
}

.text-overlay {
    color: white;
    margin-top: 5px;
}

.home-main-title {
    color: black;
    font-size: 5.5rem;
    /* font-family: Mistral; */
    font-family: Popins-Semibold;
    margin-top: 3rem;
    font-weight: normal;
}

.home-main-intro {
    display: flex;
    column-gap: 1rem;
    padding: 0rem 0 2rem;
}

.home-main-title-svg {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}

.home-main-title-svg img {
    position: absolute;
    top: 77%;
    left: 0%;
    width: 100%;
}

.home-main-title-desc {
    padding: 3rem 0;
    font-size: 1.18rem;
    text-align: center;
    font-family: Popins-Regular;
}

.home-main-tagline {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.home-main-tagline span {
    font-size: 1.8rem;
    font-weight: normal;
    font-family: Popins-Regular;
}

.home-main-tagline-text b {
    font-weight: 600;
}


.O {
    color: rgb(10, 169, 239);
    display: contents;
    /* font-family: Mistral; */
    font-family: Caveat;
}

.n {
    margin-left: 22px;
}

.c {
    margin-left: 17px;
}

.home-desc {
    color: black;
    text-align: left;
    font-family: Popins-Regular;
    position: relative;
    width: 100%;
    font-size: 1.8rem;
    font-weight: 400;
}

.home-desc:hover {
    font-family: Popins-Medium;
    /* font-weight: bold; */
}

.home-desc img {
    position: absolute;
    top: -70%;
    left: -50%;
    width: 200%;
}

.home-desc span {
    font-weight: 600;
    font-size: x-large;
    font-family: Popins-Medium;
}

.form-card {
    background-color: rgba(255, 255, 255, 255);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 60%;
}

.home-last-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #0093E9;
    background-image: linear-gradient(0deg, #106df6, #aae5ff);
    width: 100%;
    text-align: center;
    padding: 4rem 7rem;
}

/* Responsive styles for screens 1024px and below */
@media (min-width:2560px) {
    .home-main-title {
        color: black;
        font-size: 10.7rem;
        /* font-family: Mistral; */
        font-family: Popins-Semibold;
        font-weight: normal;
        margin-top: 3rem;
        /* padding: 6rem 6rem 1rem 6rem; */
    }

    .home-main-tagline span {
        font-size: 2.5rem;
        font-weight: 200;
    }

    .home-main-title-desc {
        padding: 3rem 0;
        font-size: 2.2rem;
        text-align: center;
    }
}

@media (max-width: 1400px){
    .home-main-title {
        color: #000;
        font-size: 5rem;
        margin-top: 0;
    }
}

@media (max-width: 1400px) and (min-width: 1025px) {
    .home-main-title {
        font-size: calc(4.2rem + (5 - 4.2) * ((100vw - 1025px) / (1400 - 1025) * 1));
    }
}


@media (max-width: 1024px) {
    .home-page-container {
        grid-template-columns: 1fr;
        height: 100%;
        padding: 4rem 2rem;
    }

    .home-main-title {
        color: #000;
        font-size: 4.2rem;
        margin-top: 0;
    }

    .home-main-intro {
        display: flex;
        column-gap: 1rem;
        padding: 0rem 0 2rem;
    }

    .home-main-title-svg {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .home-main-title-svg img {
        position: absolute;
        top: 77%;
        left: 0%;
        width: 100%;
    }

    .home-main-title-desc {
        padding: 3rem 0;
        font-size: 1.15rem;
        text-align: center;
    }

    .home-main-tagline {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .home-main-tagline span {
        font-size: 1.4rem;
        font-weight: 200;
    }

    .home-main-tagline-text b {
        font-weight: 600;
    }

    .home-desc img {
        position: absolute;
        top: -2%;
        left: -25%;
        width: 149%;
    }

    .home-section-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        /* padding-left: 1rem; */
    }

    .home-section-register-button-space {
        width: 90%;
    }

    .home-section-register-button {
        width: 100%;
        margin: 0;
        background-color: rgb(10, 169, 239);
        padding: 0.5rem 2rem 0.5rem 2rem;
        color: black;
        border-radius: 30px;
        border: none;
        font-family: Popins-Regular;
        cursor: pointer;
    }


    .background-div {
        height: 100%;
        /* Adjust background height to fit the container */
    }

    /* .first-section-grid-item-1 {
        margin-top: 4rem;
    } */

    .first-section-grid-item-2 {

        grid-template-columns: 1fr;
        border: none;
    }

    .home-desc {
        font-family: Popins-Medium;
        padding: 1rem;
        text-align: center;
        font-size: 0.9rem;
    }

    .home-desc span {
        font-size: 1rem;
    }

    .form-card {
        align-items: center;
        margin: 3rem 0 0rem 0;
        width: 80%;
        height: 60%;
        padding: 1.5rem;
    }

    .w {
        margin-left: -7px;
    }

    .n {
        margin-left: 5px;
    }

    .c {
        margin-left: 1px;
    }

    .home-last-banner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #0093E9;
        background-image: linear-gradient(0deg, #106df6, #aae5ff);
        width: 100%;
        text-align: center;
        padding: 2rem 3rem;
    }

}

@media (max-width: 1024px) and (min-width: 821px) {
    .home-main-title {
        font-size: calc(3.5rem + (3.5 - 4.2) * ((100vw - 820px) / (1024 - 820)));
    }
}



@media (max-width:820px) {
    .home-main-title {
        color: #000;
        font-size: 4.2rem;
        margin-top: 0;
    }

    .home-main-intro {
        display: flex;
        column-gap: 1rem;
        padding: 0rem 2rem 2rem 0;
        flex-direction: column;
        text-align: center;
    }

    .home-main-title-svg img {
        position: absolute;
        top: 89%;
        left: 0%;
        width: 100%;
        height: 33%;
    }

    .home-last-banner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #0093E9;
        background-image: linear-gradient(0deg, #106df6, #aae5ff);
        width: 100%;
        text-align: center;
        padding: 2rem 2rem;
    }
}




@media (max-width: 768px) {
    .home-page-container {
        grid-template-columns: 1fr;
        /* Stack columns vertically */
        height: 100%;
        /* Adjust height to fit content */
        padding: 2rem 1.7rem;
    }

    .home-main-title {
        color: #000;
        font-size: 4rem;
        margin-top: 0;
    }

    .home-main-intro {
        display: flex;
        column-gap: 1rem;
        padding: 0rem 0 2rem;
        text-align: center;
    }

    .home-main-title-svg {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .home-main-title-svg img {
        position: absolute;
        top: 87%;
        left: -1%;
        width: 100%;
    }

    .home-main-title-desc {
        padding: 3rem 0;
        font-size: 0.9rem;
        text-align: center;
    }

    .home-main-tagline {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .home-main-tagline span {
        font-size: 1.3rem;
        font-weight: 200;
    }

    .home-main-tagline-text b {
        font-weight: 600;
    }

    .home-section-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        /* padding-left: 1rem; */
    }

    .home-section-register-button-space {
        width: 90%;
    }

    .home-section-register-button {
        width: 100%;
        margin: 0;
        background-color: rgb(10, 169, 239);
        padding: 0.5rem 2rem 0.5rem 2rem;
        color: black;
        border-radius: 30px;
        border: none;
        font-family: Popins-Regular;
        cursor: pointer;
    }


    .background-div {
        height: 100%;
        /* Adjust background height to fit the container */
    }

    /* .first-section-grid-item-1 {

    } */

    .first-section-grid-item-2 {
        grid-template-columns: 1fr;
        border: none;
    }

    .home-desc {
        font-family: Popins-Medium;
        padding: 1rem;
        text-align: center;
        font-size: 0.9rem;
    }

    .home-desc span {
        font-size: 1rem;
    }

    .form-card {
        align-items: center;
        margin: 3rem 0 0rem 0;
        width: 80%;
        height: 60%;
        padding: 1.5rem;
    }

    .w {
        margin-left: -7px;
    }

    .n {
        margin-left: 5px;
    }

    .c {
        margin-left: 1px;
    }

}

@media (max-width:768px) and (min-width:450px) {
    .home-main-title  {
        font-size: calc(2.6rem + (6 - 4.6) * ((100vw - 450px) / (768 - 450)));
    }
}

@media (max-width: 450px) {
    .home-page-container {
        grid-template-columns: 1fr;
        /* Stack columns vertically */
        height: 100%;
        /* Adjust height to fit content */
        padding: 2rem 1.7rem;
    }

    .home-main-title {
        color: #000;
        font-size: 2.6rem;
        margin-top: 0;
    }

    .home-main-intro {
        display: flex;
        column-gap: 1rem;
        padding: 0rem 0 2rem;
        flex-direction: column;
        text-align: center;
    }

    .home-main-title-svg {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .home-main-title-svg img {
        position: absolute;
        top: 77%;
        left: -1%;
        width: 100%;
    }

    .home-main-title-desc {
        padding: 1rem 0;
        font-size: 0.8rem;
        text-align: start;
    }

    .home-main-tagline {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .home-main-tagline span {
        font-size: 0.9rem;
        font-weight: 200;
    }

    .home-main-tagline-text b {
        font-weight: 600;
    }

    .home-section-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        /* padding-left: 1rem; */
    }

    .home-section-register-button-space {
        width: 90%;
    }

    .home-section-register-button {
        width: 100%;
        margin: 0;
        background-color: rgb(10, 169, 239);
        padding: 0.5rem 2rem 0.5rem 2rem;
        color: black;
        border-radius: 30px;
        border: none;
        font-family: Popins-Regular;
        cursor: pointer;
    }


    .background-div {
        height: 100%;
        /* Adjust background height to fit the container */
    }

    /* .first-section-grid-item-1 {

    } */

    .first-section-grid-item-2 {
        grid-template-columns: 1fr;
        border: none;
    }

    .home-desc {
        font-family: Popins-Medium;
        padding: 1rem;
        text-align: center;
        font-size: 0.9rem;
    }

    .home-desc span {
        font-size: 1rem;
    }

    .form-card {
        align-items: center;
        margin: 3rem 0 0rem 0;
        width: 80%;
        height: 60%;
        padding: 1.5rem;
    }

    .w {
        margin-left: -7px;
    }

    .n {
        margin-left: 5px;
    }

    .c {
        margin-left: 1px;
    }

    .home-last-banner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #0093E9;
        background-image: linear-gradient(0deg, #106df6, #aae5ff);
        width: 100%;
        text-align: center;
        padding: 2rem 1.7rem;
    }

}

@media (max-width:450px) and (min-width:321px) {
    .home-main-title  {
        font-size: calc(2.5rem + (2.6 - 2) * ((100vw - 320px) / (450 - 320)));
    }
}
@media (max-width: 320px) {
    .home-page-container {
        grid-template-columns: 1fr;
        /* Stack columns vertically */
        height: 100%;
        /* Adjust height to fit content */
        padding: 2rem 1.7rem;
    }

    .home-main-title {
        color: #000;
        font-size: 2rem;
        margin-top: 0;
    }

    .home-main-intro {
        display: flex;
        column-gap: 1rem;
        padding: 0rem 0 2rem;
        flex-direction: column;
        text-align: center;
    }

    .home-main-title-svg {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .home-main-title-svg img {
        position: absolute;
        top: 83%;
        left: -1%;
        width: 100%;
    }

    .home-main-title-desc {
        padding: 1rem 0;
        font-size: 0.8rem;
        text-align: start;
    }

    .home-main-tagline {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .home-main-tagline span {
        font-size: 0.9rem;
        font-weight: 200;
    }

    .home-main-tagline-text b {
        font-weight: 600;
    }

    .home-section-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        /* padding-left: 1rem; */
    }

    .home-section-register-button-space {
        width: 90%;
    }

    .home-section-register-button {
        width: 100%;
        margin: 0;
        background-color: rgb(10, 169, 239);
        padding: 0.5rem 2rem 0.5rem 2rem;
        color: black;
        border-radius: 30px;
        border: none;
        font-family: Popins-Regular;
        cursor: pointer;
    }


    .background-div {
        height: 100%;
        /* Adjust background height to fit the container */
    }

    /* .first-section-grid-item-1 {

    } */

    .first-section-grid-item-2 {
        grid-template-columns: 1fr;
        border: none;
    }

    .home-desc {
        font-family: Popins-Medium;
        padding: 1rem;
        text-align: center;
        font-size: 0.9rem;
    }

    .home-desc span {
        font-size: 1rem;
    }

    .form-card {
        align-items: center;
        margin: 3rem 0 0rem 0;
        width: 80%;
        height: 60%;
        padding: 1.5rem;
    }

    .w {
        margin-left: -7px;
    }

    .n {
        margin-left: 5px;
    }

    .c {
        margin-left: 1px;
    }

    .home-last-banner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #0093E9;
        background-image: linear-gradient(0deg, #106df6, #aae5ff);
        width: 100%;
        text-align: center;
        padding: 2rem;
    }

}




.Globe-N-Commerce {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100vh;
    background-color: black;
}

.Globe {
    /* flex: 0 0 40%; */
    min-width: 100%;
}

/* .Globe > {
    max-width: 100%;
} */



.N-Commerce {
    flex: 0 0 53%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Bebas-Regular;
    /* padding: 0rem 7.5rem; */
    padding-right: 6.5rem;
}

.N-Commerce>h3>strong {
    display: flex;
    font-family: Bebas-Regular;
    font-size: 9.6rem;
    color: #fff;
    font-weight: 600;
    width: 100%;
    margin-top: 0;
    text-align: center;
    line-height: 90%;
    white-space: nowrap;
    /* to add gap between the lines */
}

.N-Commerce p {
    color: #0AA9EF;
    align-self: center;
    font-size: 40px;
    width: fit-content;
    font-family: Bebas-Regular;
}

.N-Commerce a {
    text-transform: uppercase;
    letter-spacing: 2px;
    width: 200px;
    height: 50px;
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding: 20px;
    list-style: none;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
}

.N-Commerce a:hover {
    color: #0875e2;
    transition: 1s;

}

@media (max-width:1440px) {
    .N-Commerce>h3>strong {
        display: flex;
        font-family: Bebas-Regular;
        font-size: 8rem;
        color: #fff;
        font-weight: 600;
        width: 100%;
        margin-top: 0;
        text-align: center;
        line-height: 90%;
    }
}

@media (max-width: 1440px) and (min-width: 1025px) {
    .N-Commerce > h3 > strong {
        font-size: calc(6rem + (8 - 6) * ((100vw - 1024px) / (1440 - 1024)));
    }
}


@media (max-width:1024px) {
    .N-Commerce {
        flex: 0 0 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-family: Bebas-Regular;
        padding: 0rem 4rem;
        padding-right: 3rem;
        font-size: 5rem;
    }

    .N-Commerce>h3>strong {
        display: flex;
        font-family: Bebas-Regular;
        font-size: 6rem;
        color: #fff;
        font-weight: 600;
        width: 100%;
        margin-top: 0;
        text-align: center;
        line-height: 90%;
        /* to add gap between the lines */
    }

    .Globe-N-Commerce {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 50vh;
        background-color: black;
    }
}

@media (max-width:1024px) and (min-width:821px) {
    .N-Commerce > h3 > strong {
        font-size: calc(6rem + (6 - 4.3) * ((100vw - 820px) / (1024 - 820)));
        white-space: nowrap;
    }
}

@media (max-width:820px) {
    .Globe-N-Commerce {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 50vh;
        background-color: black;
    }

    .N-Commerce>h3>strong {
        display: flex;
        font-family: Bebas-Regular;
        font-size: 4.3rem;
        color: #fff;
        font-weight: 600;
        width: 100%;
        margin-top: 0;
        text-align: center;
        line-height: 90%;
        white-space: nowrap;
        /* to add gap between the lines */
    }

    .N-Commerce p {
        color: #0AA9EF;
        align-self: center;
        font-size: 35px;
        width: fit-content;
        font-family: Bebas-Regular;
    }
}

@media (max-width: 768px) {

    .N-Commerce {
        padding: 0rem;
    }

    .N-Commerce>h3>strong {
        font-size: 10rem;
        color: #fff;
        padding: 1rem;
    }

    .Globe {
        flex: 0 0 40%;

        /* padding: 2rem 0; */
    }

    .canvas {
        height: 300px !important;
    }

    .Nomads-list-bussiness {
        display: flex;
        flex-direction: column;

    }

    .Nomad-List-Your-Bussiness {
        font-size: 20px;
        font-weight: 100px;
        padding: 0;
        display: flex;
        flex: 1;
        margin: 0;
        border: none;
        color: white;

    }

    .Globe-N-Commerce {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 4rem 0;
    }

    .Globe img {
        width: 100%;
    }

    .N-Commerce h3 {
        font-family: BebasNeue-Regular;
        font-size: 40px;
        color: #fff;
        font-weight: 200;
        position: relative;
        text-align: center;
        letter-spacing: -2px;
    }


}

@media (max-width:768px) and (min-width:450px) {
    .N-Commerce > h3 > strong {
        font-size: calc(5.7rem + (10 - 5.7) * ((100vw - 450px) / (768 - 450)));
    }
}

@media (max-width: 450px) {

    .N-Commerce {
        padding: 0rem;
    }

    .N-Commerce>h3>strong {
        font-size: 5.7rem;
        color: #fff;
        padding: 1rem;
    }

    .Globe {
        flex: 0 0 40%;
        /* padding: 2rem 0; */
    }

    .Nomads-list-bussiness {
        display: flex;
        flex-direction: column;

    }

    .Nomad-List-Your-Bussiness {
        font-size: 20px;
        font-weight: 100px;
        padding: 0;
        display: flex;
        flex: 1;
        margin: 0;
        border: none;
        color: white;

    }

    .Globe-N-Commerce {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 2rem 0;
    }

    .Globe img {
        width: 100%;
    }

    .N-Commerce h3 {
        font-family: BebasNeue-Regular;
        font-size: 40px;
        color: #fff;
        font-weight: 200;
        position: relative;
        text-align: center;
        letter-spacing: -2px;
    }


}

@media (max-width: 426px) {

    .N-Commerce {
        padding: 0rem;
    }

    .N-Commerce>h3>strong {
        font-size: 5.3rem;
        color: #fff;
        padding: 1rem;
    }

    .Globe {
        flex: 0 0 40%;
        /* padding: 2rem 0; */
    }

    .Nomads-list-bussiness {
        display: flex;
        flex-direction: column;

    }

    .Nomad-List-Your-Bussiness {
        font-size: 20px;
        font-weight: 100px;
        padding: 0;
        display: flex;
        flex: 1;
        margin: 0;
        border: none;
        color: white;

    }

    .Globe-N-Commerce {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 2rem 0;
    }

    .Globe img {
        width: 100%;
    }

    .N-Commerce h3 {
        font-family: BebasNeue-Regular;
        font-size: 40px;
        color: #fff;
        font-weight: 200;
        position: relative;
        text-align: center;
        letter-spacing: -2px;
    }


}

@media (max-width:426px) and (min-width:321px) {
    .N-Commerce > h3 > strong {
        font-size: calc(4rem + (5.3 - 4) * ((100vw - 320px) / (426 - 320)));
    }
}
@media (max-width: 320px) {

    .N-Commerce {
        padding: 0rem;
    }

    .N-Commerce>h3>strong {
        font-size: 4rem;
        color: #fff;
        padding: 1rem;
    }

    .N-Commerce p {
        color: #0AA9EF;
        align-self: center;
        font-size: 33px;
        width: fit-content;
        font-family: Bebas-Regular;
    }

    .Globe {
        flex: 0 0 40%;
        /* padding: 2rem 0; */
    }

    .Nomads-list-bussiness {
        display: flex;
        flex-direction: column;

    }

    .Nomad-List-Your-Bussiness {
        font-size: 20px;
        font-weight: 100px;
        padding: 0;
        display: flex;
        flex: 1;
        margin: 0;
        border: none;
        color: white;

    }

    .Globe-N-Commerce {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 2rem 0;
    }

    .Globe img {
        width: 100%;
    }

    .N-Commerce h3 {
        font-family: BebasNeue-Regular;
        font-size: 40px;
        color: #fff;
        font-weight: 200;
        position: relative;
        text-align: center;
        letter-spacing: -2px;
    }


}

.one-platform-section * {
    font-size: 2.5rem !important;
}

@media (max-width:1024px) {
    .one-platform-section * {
        font-size: 2.5rem !important;
    }
}

@media (max-width:768px) {
    .one-platform-section * {
        font-size: 2rem !important;
    }
}

@media (max-width:426px) {
    .one-platform-section * {
        font-size: 1.7rem !important;
    }
}



.slick-next:before {
    content: '→';
    right: -20%;
}





.button_space {
    display: flex;
    align-items: center;
    justify-content: center;

}

.btn.btn-secondary.dropdown-toggle.w-100 {
    margin: 0;
    background-color: black;
}

.submit-button {
    width: 50%;
    border-radius: 4rem;
    background-color: black;
    color: white;
    padding: 0.5rem;
}


.hero-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Bebas-Regular;
    flex-direction: column;
    padding: 8rem 0 8rem;
    background-color: black;
    color: white;
}

.backend-panel-container {
    padding: 4rem 6rem 4rem 6rem;
    background-color: rgb(249, 250, 251);
    color: black;
    margin: 2rem 0rem 2rem 0;
    border: 1px solid white;
    height: auto;
}

.backend-panel-container h2 {
    color: black;
    padding: 2rem 2rem 0 2rem;
    text-align: center;
}

.backend-panel {
    display: grid;
    grid-template-columns: 15% 85%;
    /* Sidebar takes 30%, content takes 70% */
    min-height: 100%;
    /* Full viewport height */
    padding: 2rem 2rem 2rem 1rem;
    background-color: white;
    gap: 1rem;
    border-radius: 20px;
}

.backend-panel-sidebar {
    background-color: white;
    color: black;
    padding: 1rem;
    height: fit-content;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    /* Drop shadow */
    position: sticky;
    /* Make the sidebar sticky */
    top: 13%;
}

.backend-sidebar a.nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0 1rem 0;
    color: black;
    border-bottom: 1px solid gray;
    font-family: Popins-semibold;
    border-radius: 10px;
    font-size: 0.8rem;
}

.backend-sidebar a.nav-link:hover {
    background-color: black;
    color: white;
    border-radius: 10px;
}

.backend-sidebar a.nav-link.active {
    background-color: black;
    color: white;
    border-radius: 10px;
}


.backend-sidebar-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 1rem 0;

}

.backend-sidebar-logo {
    display: flex;
    width: 120px;
}

.backend-sidebar-logo img {
    width: 100%;
    object-fit: contain;
}

.backend-panel-right {
    height: auto;

}


.backend-panel-content {
    padding: 1rem;
    background-color: #ecf0f1;
    /* Example background color for the content area */
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    /* Drop shadow */
    display: flex;
    flex-direction: column;

}

.backend-panel-header {
    padding: 1rem;
    text-align: center;
    font-family: popins-regular;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    border: 1px solid rgb(182, 182, 182);
    border-radius: 20px;
    margin: 0.3rem 0.3rem 1rem 0.3rem;
    position: sticky;
    /* Make the header sticky */
    top: 12.5%;
    /* Stick to the top of the viewport */
    background-color: white;
    /* Ensure the background is consistent */
    z-index: 2;
    /* Ensure it stays above content */
}



.backend-panel-content::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, and Opera */
}


@media (max-width:768px) {
    .backend-panel {
        height: fit-content;
        grid-template-columns: 1fr;
    }

    .backend-panel-container {
        padding: 0.5rem;

    }

    .backend-sidebar {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
    }

    .backend-panel-sidebar {
        position: static;
        /* Remove sticky positioning */
        overflow-x: auto;
        /* Enable horizontal scrolling */
        white-space: nowrap;
        /* Prevent line breaks */
    }

    .backend-panel-sidebar::-webkit-scrollbar {
        display: none;
    }


    .backend-sidebar a.nav-link {
        margin-right: 1rem;
        /* Add spacing between items */
        border-bottom: none;
        /* Remove bottom border */
        padding: 0rem 0.3rem 0 0.3rem;
    }

    .backend-panel-header {
        position: sticky;
    }

    .backend-sidebar-header {
        justify-content: space-between;
        display: flex;
        justify-content: center;
        position: sticky;
        left: 0;
    }
}

.dashboard-container-home {
    color: black;
}

.hero-desc {
    font-size: 320px;
    text-align: center;
    line-height: 0.9;
    font-weight: 600;
    padding: 4rem 1rem 4rem 1rem;
}

.hero-desc2 {
    font-size: 80px;
    margin-top: '3rem';
    color: blue;
    line-height: '0.9'
}

@media (max-width: 768px) {
    .hero-container {
        padding-top: 16rem;
        padding-bottom: 10rem;
    }

    .hero-desc {
        font-family: Bebas-Regular;
        font-size: 80px;
        padding: 0;
        font-weight: 600;
        position: relative;
        text-align: center;
        line-height: 5rem;
        letter-spacing: 0px;
    }

    .hero-desc2 {
        text-align: center;
        font-size: 50px;
        color: blue;
    }
}

.hero-button {
    font-weight: 500;
    font-size: 20px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    color: black;
    /* border: 2px solid var(--color-primary); */
    border: 2px solid #fff;
    font-family: Bebas-Regular;
    text-transform: uppercase;
    letter-spacing: 2px;
    background-color: white;
}

.hero-button:hover {
    border: 1px solid black;
}


/* world map */

.world-map {
    display: flex;
    flex-direction: column;
    background-color: black;
    color: white;
    margin-top: 2rem;
    width: 100%;
    height: 500px;
}

.world-title {
    text-align: center;
    padding: 1rem;

}

.world-title>h2 {
    font-family: Popins-Regular;
    font-size: 60px;
    font-weight: 700;
    position: relative;
    color: black;

}

.image-space {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-world {
    background-color: black;
    width: 100%;
    border-radius: 1rem;
}


/* .website-builder {
    background-color: black;
    color: white;
    overflow: hidden;
    height: 100vh;

} */

/* 
.website-builder-title{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    margin-bottom: 6rem;
}

.website-builder-grid-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
} */

.website-container-master {
    height: 100%;

}

.website-container-master>* {
    font-family: Popins-Regular;
}

.website-panel-container {
    padding: 4rem 8rem 4rem 8rem;
    background-color: #f7f7f7;
    color: black;

}

.website-panel-container h2 {
    color: black;
    /* padding: 2rem; */
    text-align: center;
    margin-bottom: 20px;
    font-size: 3.1rem;
}

.website-panel-container h3 {
    color: black;
    /* padding: 2rem; */
    text-align: center;
    margin-bottom: 20px;
}

.website-panel-container p {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 10px;
}

.website-panel {
    min-height: 100%;
    padding: 0rem 0rem 0rem 0rem;
    gap: 1rem;
    border-radius: 20px;
}

/* .website-panel-header {

} */

.website-panel-sidebar {
    background-color: white;
    color: black;
    padding: 1rem;
    height: fit-content;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    /* Drop shadow */
    position: sticky;
    /* Make the sidebar sticky */
    top: 13%;
}

.website-sidebar a.nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0 1rem 0;
    color: black;
    border-bottom: 1px solid gray;
    font-family: Popins-semibold;
    border-radius: 10px;
    font-size: 0.8rem;
}

.website-sidebar a.nav-link:hover {
    background-color: black;
    color: white;
    border-radius: 10px;
}

.website-sidebar .nav-link.active {
    background-color: black;
    color: white;
    border-radius: 10px;
}

.website-sidebar-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 1rem 0;
}

.website-sidebar-logo {
    display: flex;
    width: 120px;
}

.website-sidebar-logo img {
    width: 100%;
    object-fit: contain;
}

.website-panel-right {
    height: auto;
}

.website-panel-right a {
    display: block;
    color: black;
    font-weight: normal;
    font-family: Popins-Semibold;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
}

.website-panel-right a:hover {
    text-decoration: underline;
    color: #0875e2;
}

.website-panel-content {
    display: grid;
    grid-template-columns: 50% 1fr;
    padding: 2rem 0;
    gap: 2rem;

    margin-bottom: 20px;
}

.website-panel-right .template-preview-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.template-preview-container img {
    width: 100%;
    height: 100%;

    transition: all ease 0.3s;
    vertical-align: middle;
}


.website-panel-content::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, and Opera */
}

@media (max-width : 1440px) {
    .website-panel-container h2 {
        color: black;
        /* padding: 2rem; */
        text-align: center;
        margin-bottom: 20px;
        font-size: 2.9rem;
    }
}




@media (max-width:1024px) {
    .website-panel-container {
        padding: 4rem;
        background-color: #f7f7f7;
        color: black;

    }

    .website-panel-content {
        display: grid;
        grid-template-columns: 1fr;
        padding: 2rem 0;
        gap: 2rem;

        margin-bottom: 20px;
    }

    .website-panel-container h2 {
        color: black;
        /* padding: 2rem; */
        text-align: center;
        margin-bottom: 20px;
        font-size: 2.1rem;
    }

    .website-panel-right .template-preview-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    }

    .template-preview-container img {
        width: 100%;
        height: 100%;
        transition: all ease 0.3s;
        vertical-align: middle;
    }
}

@media (max-width:820px) {
    .website-panel-container {
        padding: 4rem 2rem;
        background-color: #f7f7f7;
        color: black;
    }

    .website-panel-content {
        display: grid;
        grid-template-columns: 1fr;
        padding: 2rem 0;
        gap: 2rem;
        margin-bottom: 20px;
    }

    .website-panel-container h2 {
        color: black;
        /* padding: 2rem; */
        text-align: start;
        margin-bottom: 20px;
        font-size: 2.1rem;
    }

    .website-panel-container h3 {
        color: black;
        /* padding: 2rem; */
        text-align: center;
        margin-bottom: 20px;
    }

    .custom-image-biznest-container {
        margin: 0 0 2rem 0 !important;
        height: 100% !important;
    }
}

@media (max-width:768px) {
    .website-container-master {
        height: 100%;
    }

    .website-panel {
        height: fit-content;
        /* grid-template-columns: 1fr;
        padding: 1rem; */
    }

    .website-panel-container {
        padding: 2rem 1.7rem;
    }

    .website-sidebar {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
    }

    .website-panel-sidebar {
        position: static;
        /* Remove sticky positioning */
        overflow-x: auto;
        /* Enable horizontal scrolling */
        white-space: nowrap;
        /* Prevent line breaks */
    }

    .website-panel-sidebar::-webkit-scrollbar {
        display: none;
    }

    .website-panel-content {
        grid-template-columns: 1fr;
    }


    .website-sidebar a.nav-link {
        margin-right: 1rem;
        /* Add spacing between items */
        border-bottom: none;
        /* Remove bottom border */
        padding: 0rem 0.3rem 0 0.3rem;
    }

    .website-panel-header {
        text-align: left;
    }

    .website-panel-header h2 {
        font-size: 2rem;
        text-align: left;
    }

    .website-panel-header h3 {
        font-size: 1.3rem;
        text-align: left;
    }

    .website-panel-header p {
        font-size: 1rem;
        text-align: left;
    }

    .website-sidebar-header {
        justify-content: space-between;
        display: flex;
        justify-content: center;
        position: sticky;
        left: 0;
    }

    .website-panel-right .template-preview-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    }

    .template-preview-container img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: all ease 0.3s;
        vertical-align: middle;
    }
}

@media (max-width:426px) {
    .website-container-master {
        height: 100%;
    }

    .website-panel {
        height: fit-content;
        /* grid-template-columns: 1fr;
        padding: 1rem; */
    }

    .website-panel-container {
        padding: 2rem 1.7rem;
    }

    .website-sidebar {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
    }

    .website-panel-sidebar {
        position: static;
        /* Remove sticky positioning */
        overflow-x: auto;
        /* Enable horizontal scrolling */
        white-space: nowrap;
        /* Prevent line breaks */
    }

    .website-panel-sidebar::-webkit-scrollbar {
        display: none;
    }

    .website-panel-content {
        grid-template-columns: 1fr;
    }


    .website-sidebar a.nav-link {
        margin-right: 1rem;
        /* Add spacing between items */
        border-bottom: none;
        /* Remove bottom border */
        padding: 0rem 0.3rem 0 0.3rem;
    }

    .website-panel-header {
        text-align: left;
    }

    .website-panel-header h2 {
        font-size: 2.5rem;
        text-align: left;
    }

    .website-panel-header h3 {
        font-size: 1.2rem;
        text-align: left;
    }

    .website-panel-header p {
        font-size: 1rem;
        text-align: left;
    }

    .website-sidebar-header {
        justify-content: space-between;
        display: flex;
        justify-content: center;
        position: sticky;
        left: 0;
    }

    .website-panel-right .template-preview-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    }

    .template-preview-container img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: all ease 0.3s;
        vertical-align: middle;
    }
}

@media (max-width:426px) and (min-width:322px) {
    .website-panel-header h2 {
        font-size: calc(1.5rem + (2.5 - 1.5) * ((100vw - 321px) / (426 - 321)));
    }
}
@media (max-width:321px) {
    .website-container-master {
        height: 100%;
    }

    .website-panel {
        height: fit-content;
        /* grid-template-columns: 1fr;
        padding: 1rem; */
    }

    .website-panel-container {
        padding: 2rem 1.8rem;
    }

    .website-sidebar {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
    }

    .website-panel-sidebar {
        position: static;
        /* Remove sticky positioning */
        overflow-x: auto;
        /* Enable horizontal scrolling */
        white-space: nowrap;
        /* Prevent line breaks */
    }

    .website-panel-sidebar::-webkit-scrollbar {
        display: none;
    }

    .website-panel-content {
        grid-template-columns: 1fr;
    }


    .website-sidebar a.nav-link {
        margin-right: 1rem;
        /* Add spacing between items */
        border-bottom: none;
        /* Remove bottom border */
        padding: 0rem 0.3rem 0 0.3rem;
    }

    .website-panel-header {
        text-align: left;
    }

    .website-panel-header h2 {
        font-size: 1.5rem;
        text-align: left;
    }

    .website-panel-header h3 {
        font-size: 1.2rem;
        text-align: left;
    }

    .website-panel-header p {
        font-size: 1rem;
        text-align: left;
    }

    .website-sidebar-header {
        justify-content: space-between;
        display: flex;
        justify-content: center;
        position: sticky;
        left: 0;
    }

    .website-panel-right .template-preview-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    }

    .template-preview-container img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: all ease 0.3s;
        vertical-align: middle;
    }
}


/* testimonials */

.testimonial-section {
    /* background-color: #cae4ff !important; */
    margin-top: 0 !important;
    padding: 1rem 6.5rem 1rem 8.5rem;
}

.testimonial-section>* {
    font-family: Popins-Medium;
}

@media (max-width:768px) {
    .testimonial-section {
        /* background-color: #cae4ff !important; */
        margin-top: 0 !important;
        padding: 0 1.9rem;
        padding-right: 1.3rem;
    }
}

@media (max-width:1024px) {
    .testimonial-section {
        /* background-color: #cae4ff !important; */
        margin-top: 0 !important;
        padding: 0 1.6rem 0 1.3rem;
    }
}





/* slider */


.home-website-slick-dots {
    bottom: 10px;
    /* Position dots at the bottom of the slider */
    display: none !important;
    justify-content: center;
}

.home-website-slick-dots li {
    margin: 0 5px;
    /* Space between dots */
}

.home-website-slick-dots button {
    background-color: white;
    /* Default color for inactive dots */
    border-radius: 50%;
    width: 10px;
    height: 10px;
    padding: 0;
}

.home-website-slick-dots .slick-active button {
    background-color: #000;
    /* Color for the active dot */
}

.home-website-slick-prev,
.home-website-slick-next {
    width: 10px;
    /* Width of the arrow */
    height: 10px;
    /* Height of the arrow */
    background-color: transparent;
    /* Background color of the arrow */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    /* Color of the arrow icon */
    font-size: 20px;
    /* Size of the arrow icon */
    cursor: pointer;
    /* display: none; */
}

.home-website-slick-prev {
    left: 10px;
    /* Position from the left side */
    z-index: 1;
    /* Ensure it’s above other content */
}

.home-website-slick-next {
    right: 10px;
    /* Position from the right side */
    z-index: 1;
    /* Ensure it’s above other content */
}

/* Optional: Customize the appearance of the arrow icons */
.home-website-slick-prev::before,
.home-website-slick-next::before {
    font-size: 20px;
    line-height: 1;
    z-index: 10;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}



@media (max-width:768px) {


    .website-builder {
        grid-template-columns: 1fr;
    }

    .website-builder-grid-1 {
        width: 100%;
        height: 100%;
    }

}

.template-slide {
    padding: 0 10px;
    /* Adjust left and right padding for spacing */
    margin-top: 10px;
}


.template-container>div {
    padding: 0 4rem 0 4rem;
}

.template-card {
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    width: 100%;
    height: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    border-radius: 20px;
}

.slick-slide.slick-active.slick-current .template-card {
    transform: scale(1.5);
    border-radius: 20px;

}

.slick-slide.slick-active .template-card {
    /* margin-left: 25rem;  */
    /* background-color: blue; */
    border-radius: 20px;
}

.template-card img {
    width: 100%;
    height: 100%;
    /* Adjust height to fit within the card */
    object-fit: cover;
    object-position: top;
}

.last-section * {
    font-family: Popins-Regular;
}

@media (max-width:768px) {

    .template-slide {
        padding: 0 10px;
        /* Adjust left and right padding for spacing */
        width: 70% !important;
        margin-left: 13%;
    }

    .template-card {
        padding: 0;
    }

    .template-container>div {
        padding: 0;
    }

    .template-content-image img {
        object-fit: cover;
    }
}